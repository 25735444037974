// mutations.js

import { gql } from '@apollo/client';

export const GET_ACCOUNT_BY_ID = gql`
  query getAccountByUserName($username: String!) {
    getAccountByUserName(username: $username) {
      userUID
      fullName
      username
      websiteURL
      profilePicture
      biography
      followers {
        followerUID
      }
    }
  }
`;

export const FOLLOW_ACCOUNT = gql`
  mutation FollowAccount($followerId: ID!, $followingId: ID!) {
    followAccount(followerId: $followerId, followingId: $followingId) {
      userUID
    }
  }
`;

export const UNFOLLOW_ACCOUNT = gql`
  mutation UnfollowAccount($followerId: ID!, $followingId: ID!) {
    unfollowAccount(followerId: $followerId, followingId: $followingId) {
      userUID
    }
  }
`;
