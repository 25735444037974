import React, { createContext, useContext, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import { LOGIN_MUTATION, LOGOUT_MUTATION } from './mutations';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userUID, setUserUID] = useState(null);
  const [id, setID] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [loginMutation] = useMutation(LOGIN_MUTATION);
  const [logoutMutation] = useMutation(LOGOUT_MUTATION);

  useEffect(() => {
    const token = Cookies.get('_scom_at_jwt1_');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserUID(decodedToken.userUID);
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Invalid token:', error);
        Cookies.remove('_scom_at_jwt1_');
      }
    }
  }, []);

  const login = async (credentials) => {
    setIsLoading(true);
    try {
      const { data } = await loginMutation({ variables: { input: credentials } });
      const token = data.login.token;
      Cookies.set('_scom_at_jwt1_', token, { secure: true, sameSite: 'Strict' });
      const decodedToken = jwtDecode(token);
      setUserUID(decodedToken.userUID);
      setID(data.login.account.id);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Erreur lors de la connexion :', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      await logoutMutation();
      Cookies.remove('_scom_at_jwt1_');
      setIsAuthenticated(false);
      setUserUID(null);
      setID(null);
    } catch (error) {
      console.error('Erreur lors de la déconnexion :', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, userUID, setUserUID, setIsAuthenticated, id, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
