import { gql } from '@apollo/client';

export const CREATE_COMMENT = gql`
mutation CreateComment($publicationId: ID!, $userId: String!, $content: String!) {
  createComment(publicationId: $publicationId, userId: $userId, content: $content) {
    id
    publicationId
    userId
    content
    createdAt
    account {
      username
      profilePicture
    }
  }
}
`;

export const DELETE_COMMENT = gql`
 mutation DeleteComment($commentId: ID!, $userId: String!) {
  deleteComment(commentId: $commentId, userId: $userId) {
    success
    message
  }
}
`;

export const GET_COMMENTS_BY_PUBLICATION_ID = gql`
  query GetCommentsByPublicationId($publicationId: ID!) {
    getCommentsByPublicationId(publicationId: $publicationId) {
      id
      publicationId
      userId
      content
      createdAt
      account {
        username
        profilePicture
      }
    }
  }
`;
