import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ACCOUNT_BY_UID, UPDATE_ACCOUNT } from './qm';
import { useAuth } from '../AuthContext';
import { Form, Input, Upload, Button, message, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Profile = () => {
  const { isAuthenticated, userUID } = useAuth();
  const [form] = Form.useForm();
  const [loadingMutation, setLoadingMutation] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const selectedLanguage = localStorage.getItem("preferredLanguage") || "fr";

  const navigate = useNavigate();

  const { loading, error, data, refetch } = useQuery(GET_ACCOUNT_BY_UID, {
    variables: { userUID },
    skip: !userUID,
  });

  const [updateAccount] = useMutation(UPDATE_ACCOUNT);

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data.getAccountByUID);
      if (data.getAccountByUID.profilePicture) {
        setFileList([{ uid: '-1', url: data.getAccountByUID.profilePicture }]);
      }
    }
  }, [data, form]);

  const handleSave = async (values) => {
    try {
      setLoadingMutation(true);
      await updateAccount({
        variables: {
          id: data.getAccountByUID.id,
          input: { ...values, profilePicture: fileList.length > 0 ? fileList[0].url : null },
        },
      });
      refetch();
      setLoadingMutation(false);
      message.success('Account updated successfully');
      setModalVisible(false); // Close the modal after saving
    } catch (error) {
      setLoadingMutation(false);
      message.error('Error updating account');
    }
  };

  const handleLogoChange = async (file) => {
    try {
      setLoadingMutation(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'cloudpscomapp'); // Replace with your Cloudinary upload preset
      formData.append('folder', 'piscomapp');

      const response = await axios.post('https://api.cloudinary.com/v1_1/dqdjbibmo/image/upload', formData);
      const imageURL = response.data.secure_url;

      form.setFieldsValue({ profilePicture: imageURL });
      setFileList([{ uid: file.uid, url: imageURL }]);
      setLoadingMutation(false);
      return false;
    } catch (error) {
      setLoadingMutation(false);
      message.error('Error uploading image');
      return false;
    }
  };

  const handlePreview = (file) => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleRemoveLogo = () => {
    form.setFieldsValue({ profilePicture: null });
    setFileList([]);
    message.success('Logo removed successfully');
  };

  const handleCancel = () => setPreviewVisible(false);
  
  const handleModalCancel = () => setModalVisible(false);

  if (!isAuthenticated) {
    navigate('/u/login');
    return null;
  }

  if (loading || loadingMutation) return <div>Loading...</div>;
  if (error) return <div>Error fetching account information: {error.message}</div>;

  return (
    <div style={{display: "grid"}}>
      <Button  style={{backgroundColor: "#007aff", borderColor: "#007aff", textAlign: "center", display: "block"}} type="default" onClick={() => setModalVisible(true)}>
                {selectedLanguage === "fr" ? "Modifier le profil" : "Edit Profile"}

      </Button>
      <Modal
        visible={modalVisible}
        title={selectedLanguage === "fr" ? "Modifier le profil" : "Edit Profile"}
        footer={null}
        onCancel={handleModalCancel}
      >
        <Form form={form} onFinish={handleSave}>
          <Form.Item label="Logo" name="profilePicture">
            <Upload
              showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
              listType="picture-circle"
              fileList={fileList}
              onPreview={handlePreview}
              beforeUpload={handleLogoChange}
              onRemove={handleRemoveLogo}
            >
              {fileList.length >= 1 ? null : (
                <div>
                  <UploadOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item label={selectedLanguage === "fr" ? "Nom" : "Name"} name="fullName">
            <Input />
          </Form.Item>
          <Form.Item label={selectedLanguage === "fr" ? "Biography" : "Biographie"}  name="biography">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label={selectedLanguage === "fr" ? "Website" : "Site web"} name="websiteURL">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loadingMutation}>
              {selectedLanguage === "fr" ? "Enregistrer" : "Save"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default Profile;
