import { gql } from '@apollo/client';

// Définition de la requête GraphQL
export const GET_FOLLOWING = gql`
  query GetFollowingByUser($userUID: String!) {
    getFollowingByUser(userUID: $userUID) {
      userUID
      username
      profilePicture
    }
  }
`;
