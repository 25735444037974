import React from "react";
import { Layout, Menu, Dropdown, Button } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { footerStyles, menuStyles, linkStyles } from "./Styles";

const { Footer } = Layout;

const NavigationLegal = () => {
  const { isAuthenticated } = useAuth0();
  const selectedLanguage = localStorage.getItem("preferredLanguage") || "fr";

  // Définition des textes et des liens selon la langue sélectionnée
  const TERMS_OF_USE_LINK = selectedLanguage === "fr"
    ? "https://docs.sendkr.com/legal/terms-of-use"
    : "https://docs.sendkr.com/legal/terms-of-use";
  const TERMS_OF_USE_LINK_LABEL = selectedLanguage === "fr"
    ? "Conditions d'utilisation"
    : "Terms of Use";
  const PRIVACY_POLICY_LINK = selectedLanguage === "fr"
    ? "https://docs.sendkr.com/legal/privacy-policy"
    : "https://docs.sendkr.com/legal/privacy-policy";
  const PRIVACY_POLICY_LINK_LABEL = selectedLanguage === "fr"
    ? "Politique de confidentialité"
    : "Privacy Policy";
  const BG_LINK = selectedLanguage === "fr"
    ? "https://docs.sendkr.com/advance-information/report-a-problem"
    : "https://docs.sendkr.com/advance-information/report-a-problem";
  const BG_LINK_LABEL = selectedLanguage === "fr"
    ? "Signaler un problème"
    : "Report a problem";

  const footerMenu = (
    <Menu style={menuStyles}>
      <Menu.Item key="1">
        <Button
          type="link"
          href={TERMS_OF_USE_LINK}
          target="_blank"
          style={linkStyles}
        >
          {TERMS_OF_USE_LINK_LABEL}
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button
          type="link"
          href={PRIVACY_POLICY_LINK}
          target="_blank"
          style={linkStyles}
        >
          {PRIVACY_POLICY_LINK_LABEL}
        </Button>
      </Menu.Item>
      <Menu.Item key="3">
        <Button type="link" href={BG_LINK} target="_blank" style={linkStyles}>
          {BG_LINK_LABEL}
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Footer style={footerStyles}>
      <div>
        <Dropdown overlay={footerMenu}>
          <span onClick={(e) => e.preventDefault()} style={menuStyles}>
            <EllipsisOutlined />
          </span>
        </Dropdown>
      </div>
    </Footer>
  );
};

export default NavigationLegal;
