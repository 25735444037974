import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from '@apollo/client';
import client from './graphql/apollo/client'; // Import du client Apollo
import Settings from "./pages/Settings";
import Publication from "./pages/Publication";
import UserEditor from "./pages/User";
import { ConfigProvider } from "antd";
import Brand from "./pages/Brand";
import FeedGuest from "./pages/Feed";
import Register from "./components/User/Register";
import LoginForm from "./components/User/Login";
import { AuthProvider } from "./components/User/AuthContext";
import store from '../src/store';
import { Provider } from "react-redux";
import "./index.css";
import "./rsp.css";
import "./scom.css";
import Product from "./pages/Product";
import Like from "./pages/Like";
import Subscription from "./pages/Subscription";
import Tag from "./pages/Tag";



const darkAlgorithm = {
  token: {
    colorText: "#FFFFFF",
    fontFamily:
      "system-ui, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif !important",
    borderRadius: 8,
    colorBorderHover: "#313131",
    colorTextBase: "#CCCCCC",
    colorTextSecondary: "#AAAAAA",
    colorTextHeading: "#FFFFFF",
    colorLink: "#FFFFFF",
    colorBgBase: "#101010",
    colorBgPrimary: "#101010",
    colorBgSecondary: "#101010",
    colorBgDefault: "#101010",
    colorBgLayout: "#101010",
    colorBgContainer: "#101010",
    colorBgElevated: "#101010",
    colorBgContainerDisabled: "#101010",
    colorBorder: "#313131",
    colorBorderSecondary: "#313131",
    colorBorderStyle: "#313131",
    colorBorderBg: "#313131",
    colorBorderBgDefault: "#313131",
    boxShadow: false,
    boxShadowSecondary: false,
    buttonShadow: false,
    primaryShadow: false,
  },
  components: {

    Button: {
      boxShadow: "none",
      boxShadowSecondary: "none",
      borderRadius: 6,
      borderColorDisabled: "#313131",
      colorBgContainerDisabled: "#101010",
      colorBgContainer: "#313131",
      colorText: "#FFFFFF",
      fontWeight: "700",
      colorTextSecondary: "#777777",
      colorBgTextActive: "#777777",
      colorPrimary: "#FFFFFF",
      colorPrimaryActive: "#313131",
      colorTextLightSolid: "#000",
      colorBorder: "#777777",
      colorBorderSecondary: "#777777",
      colorBorderStyle: "#777777",
      colorBorderBg: "#777777",
      colorBorderBgDefault: "#777777",
    },
    Select: {
      borderRadius: 4,
    },
    Badge: {
      colorSuccess: "#17A67D",
    },
    Input: {
      borderRadius: 4,
      colorBorder: "#313131",
      colorBorderSecondary: "#313131",
      colorBorderStyle: "#313131",
      colorBorderBg: "#313131",
      colorBorderBgDefault: "#313131",
    },
    Layout: {
      colorTextLightSolid: "#FFFFFF",
      siderBg: "#101010",
      headerBg: "#101010",
      bodyBg: "#101010",
    },
  },
};

// Thème clair
const lightAlgorithm = {
  token: {
    fontFamily:
      "system-ui, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif !important",
    borderRadius: 8,
    colorBorderHover: "#E5E5E5",
    colorTextBase: "#101010",
    colorTextSecondary: "#101010",
    colorTextHeading: "#101010",
    colorLink: "#777777",

    colorBgBase: "#FFFFFF",
    colorBgPrimary: "#FAFAFA",
    colorBgSecondary: "#F5F5F5",
    colorBgDefault: "#FFFFFF",
    colorBgLayout: "#FFFFFF",
    colorBgContainer: "#FFFFFF",
    colorBgElevated: "#FFFFFF",
    colorBgContainerDisabled: "#FFFFFF",
    colorBorder: "#E5E5E5",
    colorBorderSecondary: "#E5E5E5",
    colorBorderStyle: "#E5E5E5",
    colorBorderBg: "#E5E5E5",
    colorBorderBgDefault: "#E5E5E5",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
    boxShadowSecondary: "0 2px 8px rgba(0, 0, 0, 0.15)",
    buttonShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
    primaryShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
  },
  components: {
    Button: {
      boxShadow: "none",
      boxShadowSecondary: "none",
      borderRadius: 6,
      borderColorDisabled: "#e6e6e6",
      colorBgContainerDisabled: "#FFFFFF",
      colorBgContainer: "#FFFFFF",
      colorText: "#222333",
      fontWeight: "700",
      colorTextSecondary: "#777777",
      colorBgTextActive: "#777777",
      colorPrimary: "#1890ff",
      colorPrimaryActive: "#40A9FF",
      colorTextLightSolid: "#FFFFFF",
      colorBorder: "#e6e6e6",
      colorBorderSecondary: "#e6e6e6",
      colorBorderStyle: "#e6e6e6",
      colorBorderBg: "#e6e6e6",
      colorBorderBgDefault: "#e6e6e6",
    },
    Select: {
      borderRadius: 4,
    },
    Badge: {
      colorSuccess: "#52c41a",
    },
    Input: {
      borderRadius: 4,
      colorBorder: "#e6e6e6",
      colorBorderSecondary: "#e6e6e6",
      colorBorderStyle: "#e6e6e6",
      colorBorderBg: "#e6e6e6",
      colorBorderBgDefault: "#e6e6e6",
    },
    Layout: {
      colorTextLightSolid: "#000000",
      siderBg: "#FFFFFF",
      headerBg: "#FFFFFF",
      bodyBg: "#FFFFFF",
    },
  },
};

const auth0Config = {
  domain: "helpquit.eu.auth0.com",
  clientId: "iFY1aRxHGTfYrGsZmUR7lA93M71HMPMr",
  cacheLocation: "localstorage",
  authorizationParams: {
    redirect_uri: "https://laughing-engine-65jwrqjq4wqf557r-3000.app.github.dev",
  },
};

const Root = () => {
  const [currentTheme, setCurrentTheme] = useState("dark"); // État local pour le thème

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setCurrentTheme(savedTheme);
    }
  }, []);

  return (
    <Provider store={store}>
    <ApolloProvider client={client}>
      <ConfigProvider theme={currentTheme === 'light' ? lightAlgorithm : darkAlgorithm}>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<FeedGuest />} />
              <Route path="/u/login" element={<LoginForm />} />
              <Route path="/u/register" element={<Register />} />
              <Route path="/u/create" element={<Publication />} />
              <Route path="/u/:username" element={<Brand />} />
              <Route path="/u/likes" element={<Like />} />
              <Route path="/u/follow" element={<Subscription />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/:username" element={<Brand />} />
              <Route path="/p/:slug" element={<Product />} />
              <Route path="/tag/:tag" element={<Tag />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ConfigProvider>
    </ApolloProvider>
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
reportWebVitals();
