import React, { useState, useEffect } from 'react';
import { Avatar, Button, Card, Typography, notification } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ACCOUNT_BY_ID, FOLLOW_ACCOUNT, UNFOLLOW_ACCOUNT } from "./mutations";
import { UserOutlined } from '@ant-design/icons';
import Profile from '../../components/User/Profile';
import GetFollowing from './Following';
import GetFollowers from './Follower';

const { Title, Paragraph } = Typography;

const UHPC = ({ username, currentUserUID }) => {
  const { loading, error, data } = useQuery(GET_ACCOUNT_BY_ID, {
    variables: { username },
    fetchPolicy: 'cache-first',
  });

  const [isFollowing, setIsFollowing] = useState(false);
  const [followersCount, setFollowersCount] = useState(0);
  const [expandedBio, setExpandedBio] = useState(false);
  const selectedLanguage = localStorage.getItem("preferredLanguage") || "fr";

  useEffect(() => {
    if (data && data.getAccountByUserName) {
      const accountData = data.getAccountByUserName;
      const followers = accountData.followers || [];
      const isUserFollowing = followers.some(follower => follower.followerUID === currentUserUID);
      
      setIsFollowing(isUserFollowing);
      setFollowersCount(followers.length);
    }
  }, [data, currentUserUID]);

  const [followAccountMutation] = useMutation(FOLLOW_ACCOUNT);
  const [unfollowAccountMutation] = useMutation(UNFOLLOW_ACCOUNT);

  const followUser = async () => {
    try {
      const response = await followAccountMutation({ variables: { followerId: currentUserUID, followingId: data.getAccountByUserName.userUID } });
      if (response.data) {
        setIsFollowing(true);
        setFollowersCount(prevCount => prevCount + 1);
      }
    } catch (error) {
      console.error("Erreur lors du suivi:", error.message);
    }
  };

  const unfollowUser = async () => {
    try {
      const response = await unfollowAccountMutation({ variables: { followerId: currentUserUID, followingId: data.getAccountByUserName.userUID } });
      if (response.data) {
        setIsFollowing(false);
        setFollowersCount(prevCount => prevCount - 1);
      }
    } catch (error) {
      console.error("Erreur lors du désabonnement:", error.message);
    }
  };

  const toggleBioExpansion = () => {
    setExpandedBio(!expandedBio);
  };


  if (loading) return <p>Chargement...</p>;
  if (error) return <p>Erreur: {error.message}</p>;

  const account = data.getAccountByUserName;

  if (!account || !username) {
    return null;
  }

  const bio = account.biography || <p>No bio.</p>; // Ensure biography is a string
  const trimmedBio = bio.length > 100 ? bio.slice(0, 97) + "..." : bio;
  const displayBio = expandedBio ? bio : trimmedBio;
  

  return (
    <div style={{marginBottom: 32}}>
      <div style={{ display: "flex", gap: 16, justifyContent: "space-between"}}>
        <div className="userInformation" style={{ display: "flex", gap: 14, justifyContent: "space-between", alignItems: "center" }}>
          <div className="avatar">
          {account.profilePicture 
  ? <Avatar src={account.profilePicture} alt={account.fullName} size={74} />
  : <Avatar icon={<UserOutlined />} alt={account.fullName} size={74} />
}

          </div>
          <div>
            {account.fullName && <Title level={4} style={{ margin: 0, fontSize: 28 }}>{account.fullName}</Title>}
            {account.fullName && <span style={{ margin: 0, fontSize: 18 }}>{account.username}</span>}
          </div>
        </div>
        <div>
          
        </div>
      </div>
      <div>

   


        <div style={{ display: "flex", gap: 16 }} className="uhpc-action">
          <div style={{ width: "100%" }}>
      {bio && (
  <Paragraph style={{ fontSize: 16, margin: "16px 0" }}>
    {displayBio}
    {bio.length > 100 && (
      <Button type="link" onClick={toggleBioExpansion} style={{ padding: 0 }}>
        {selectedLanguage === "fr" ? expandedBio ? "Voir moins" : "Voir plus" : expandedBio ? "See less" : "See more"}
      </Button>
    )}
  </Paragraph>
)}


         
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 16 }}>
             
            {account.websiteURL && (
  <div >
    <Button  style={{ textAlign: "center", display: "block", width: "100%" }} type="primary" href={account.websiteURL}>
      {selectedLanguage === "fr" ? "Voir le site web" : "Website"}
    </Button>
  </div>
)}


{currentUserUID === account.userUID ? null : (
  <div>
    {isFollowing === true ? (
      <Button style={{ textAlign: "center", display: "block", width: "100%" }} type="default" onClick={unfollowUser}>
        {selectedLanguage === "fr" ? "Ne plus suivre" : "Unfollow"}
      </Button>
    ) : isFollowing === false ? (
      <Button style={{ textAlign: "center", display: "block", width: "100%" }}  type="primary" onClick={followUser}>
        {selectedLanguage === "fr" ? "Suivre" : "Follow"}
      </Button>
    ) : null}
  </div>
)}



              
            
            
            
            
            
            
            
              {currentUserUID === account.userUID && (
                <div style={{ width: "100%" }}>

                  <Profile />
      



                                    
                </div>


              )}



    {currentUserUID === account.userUID && (
                <div style={{ width: "100%" }}>

<GetFollowing  buttonText={selectedLanguage === "fr" ? "Abonnement(s)" : "Following"} />


                                    
                </div>


              )}











                <div style={{ width: "100%" }}>

<GetFollowers userFollowersId={data.getAccountByUserName.userUID} buttonText={selectedLanguage === "fr" ? `${followersCount} ${followersCount === 1 ? "abonné" : "abonnés"}` : `${followersCount} ${followersCount === 1 ? "subscriber" : "subscribers"}`} />


                                    
                </div>









            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UHPC;
