// src/store/reducers/publicationsReducer.js

const initialState = {
	likedPublications: [],
	// Other initial state properties related to publications
  };
  
  const publicationsReducer = (state = initialState, action) => {
	switch (action.type) {
	  case 'LIKE_PUBLICATION':
		// Handle adding liked publication to state
		return {
		  ...state,
		  likedPublications: [...state.likedPublications, action.payload.publicationId],
		};
	  case 'DISLIKE_PUBLICATION':
		// Handle removing disliked publication from state
		return {
		  ...state,
		  likedPublications: state.likedPublications.filter(id => id !== action.payload.publicationId),
		};
	  // Add more cases for other actions as needed
  
	  default:
		return state;
	}
  };
  
  export default publicationsReducer;
  