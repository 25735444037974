import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS_BY_USER_UID = gql`
  query GetNotificationsByUserUID($userUID: String!) {
    getNotificationsByUserUID(userUID: $userUID) {
      type
      message
      messageTranslator
      followerUsername
      userUID
      slug
      createdAt
      expirationDate
      isRead
      account {
        profilePicture
      }
    }
  }
`;

export const MARK_ALL_NOTIFICATIONS_READ = gql`
  mutation MarkAllNotificationsRead($userUID: String!) {
    markAllNotificationsRead(userUID: $userUID) {
      isRead
    }
  }
`;
