import React, { useState, useEffect, useRef } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled from '@emotion/styled';
import FeedCard from '../Feed/FeedCard';
import CustomLayout from '../../components/Layout';
import "../Feed/FeedCard/Styles/StylesFeed.scss";

import { useAuth } from '../../components/User/AuthContext';
import usePublicationActions from '../hookp/usePublicationActions';

import { useQuery } from '@apollo/client';
import { GET_SUBSCRIPTION_PUBLICATIONS, GET_LIKED_PUBLICATIONS } from './qm';

import { useInView } from 'react-intersection-observer';
import Switch from '../Feed/Switch';

const LoadStyled = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
`;

const Subscription = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [publications, setPublications] = useState([]);
  const scrollPosition = useRef(0);
  const { userUID } = useAuth();

  const { loading: queryLoading, error: queryError, data: subscriptionPublicationsData } = useQuery(GET_SUBSCRIPTION_PUBLICATIONS, {
    variables: { userUID: userUID },
    fetchPolicy: 'network-only',
  });

  const { loading: likedLoading, error: likedError, data: likedData } = useQuery(GET_LIKED_PUBLICATIONS, {
    variables: { userId: userUID },
    fetchPolicy: 'cache-first',
  });

  const { handleRepost, handleLike, handleDislike, handleDelete, handleReport } = usePublicationActions();

  const selectedLanguage = localStorage.getItem("preferredLanguage") || "fr";

  useEffect(() => {
    if (!queryLoading && !queryError && subscriptionPublicationsData && subscriptionPublicationsData.getPublicationsBySubscription) {
      setPublications(prevPublications => [...prevPublications, ...subscriptionPublicationsData.getPublicationsBySubscription]);
    }
  }, [queryLoading, queryError, subscriptionPublicationsData]);

  const formatDate = (date) => {
    const now = moment();
    const publicationDate = moment(date);
    const diffInMinutes = now.diff(publicationDate, 'minutes');
    const diffInHours = now.diff(publicationDate, 'hours');
    const diffInDays = now.diff(publicationDate, 'days');

    if (diffInMinutes < 1) {
      return '1 min';
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} mins`;
    } else if (diffInHours < 24) {
      return `${diffInHours} h`;
    } else {
      return `${diffInDays} j`;
    }
  };

  const handleScroll = () => {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (scrolledToBottom && !loading && !error) {
      setPage(page => page + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, error]);

  useEffect(() => {
    if (page > 1) {
      // Save current scroll position
      scrollPosition.current = window.pageYOffset;
      setLoading(true);
    }
  }, [page]);

  useEffect(() => {
    if (!loading && subscriptionPublicationsData) {
      const fetchedPublications = subscriptionPublicationsData.getPublicationsBySubscription;
      if (fetchedPublications.length === 0) {
        setError(new Error('No more subscriptions publications to load.'));
      } else {
        setPublications(prevPublications => [...prevPublications, ...fetchedPublications]);
        // Restore scroll position after loading new publications
        window.scrollTo(0, scrollPosition.current);
      }
      setLoading(false);
    }
  }, [loading, subscriptionPublicationsData]);

  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView && !loading && !error) {
      setPage(page => page + 1);
    }
  }, [inView, loading, error]);

  if (loading && page === 1) {
    return (
      <LoadStyled>
        <Spin
          indicator={<LoadingOutlined className="loading-start" spin />}
        />
      </LoadStyled>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <CustomLayout>
      <Switch />
      {publications
        .sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf())
        .map((publication, index) => (
          <FeedCard
            key={index}
            id={publication.originalPublicationId}
            avatar={publication.account ? publication.account.profilePicture || '/default-avatar.png' : '/default-avatar.png'}
            name={publication.account ? publication.account.fullName || 'Default Username' : 'Default Username'}
            username={publication.account ? publication.account.username || 'Default Username' : 'Default Username'}
            date={formatDate(publication.createdAt)}
            content={publication.content}
            account={publication.account ? publication.account.userUID || 'userUID' : 'userUID'}
            photos={publication.gallery}
            originalPublicationDate={formatDate(publication.originalPublicationDate)}
            video={publication.video}
            url={publication.url}
            onRepost={handleRepost}
            discount={publication.discount}
            slug={publication.slug}
            onLike={handleLike}
            onDislike={handleDislike}
            onDelete={handleDelete}
            onReport={handleReport}
            likedPublications={likedData ? likedData.getLikedPublications.map(publication => publication.originalPublicationId) : []}
            initialRepostCount={publication.repostCount}
            isLastPageCard={index === publications.length - 1}
            originalAuthor={publication.originalAuthor}
          />
        ))}
      <div ref={ref}></div>
    </CustomLayout>
  );
};

export default Subscription;
