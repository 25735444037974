import React, { Suspense, useState } from "react";
import { Layout } from "antd";
import styled from "@emotion/styled";

import Header from "../Header";
import NavigationApp from "./Navigation";

const { Content } = Layout;

const StyledContent = styled(Content)`
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 16px;
  margin-top: 0;
`;


const ChildrenContent = styled.div`
  max-width: 570px;
  margin: 0 auto;
  padding-bottom: 200px;
 
`;

const CustomLayout = ({ children }) => {


  const isActive = (pathname) => {
    return pathname === window.location.pathname;
  };

  return (
    <Layout style={{ minHeight: "100vh", paddingTop: "120px", paddingBottom: "0" }}>
    <Header />

      <StyledContent>

          <ChildrenContent>{children}</ChildrenContent>

      </StyledContent>
      <NavigationApp  isActive={isActive} />


    </Layout>
  );
};

export default CustomLayout;
