import { gql } from '@apollo/client';

export const CREATE_PUBLICATION = gql`
  mutation CreatePublication($input: PublicationInput!) {
    createPublication(input: $input) {
      id
      userId
      content
      gallery {
        url
      }
      video
      url
      createdAt
      tag
      originalPublicationId
      discount {
        code
        percentage
        color
      }
    }
  }
`;
