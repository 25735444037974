import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useAuth } from '../../../components/User/AuthContext';

const SwitchContainer = styled.div`
  display: inline-flex;
  overflow: hidden;
  margin-bottom: 50px;
  gap: 14px;
`;

const SwitchButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #2b2b2b;
  border-radius: 5rem;
  color: white;
  background-color: #1a1a1a;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover {
    background-color: #1e1e1e;
  }

  ${({ active }) =>
    active &&
    `
    background-color: #1e1e1e;
    border-color: #1e1e1e;
  `}
`;

const Switch = () => {
  const selectedLanguage = localStorage.getItem("preferredLanguage") || "fr";
  const [activeButton, setActiveButton] = useState('caverne');
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
    if (buttonName === 'caverne') {
      navigate('/');
    } else if (buttonName === 'abonnements') {
      navigate('/u/follow');
    }
  };

  return (
    <>
      {isAuthenticated && (
        <SwitchContainer>
          <SwitchButton
            active={activeButton === 'caverne'}
            onClick={() => handleClick('caverne')}
          >
            {selectedLanguage === "fr" ? 'Découvrir' : 'Discover'}
          </SwitchButton>
          <SwitchButton
            active={activeButton === 'abonnements'}
            onClick={() => handleClick('abonnements')}
          >
            {selectedLanguage === "fr" ? 'Abonnement(s)' : 'Subscription(s)'}
          </SwitchButton>
        </SwitchContainer>
      )}
    </>
  );
};

export default Switch;
