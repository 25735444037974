import React from 'react';
import { useAuth } from '../../components/User/AuthContext';
import { useQuery } from '@apollo/client';
import { GET_LIKED_PUBLICATIONS } from './qm';
import moment from 'moment';
import usePublicationActions from '../hookp/usePublicationActions';
import FeedCard from '../Feed/FeedCard';

const Like = () => {
  const { userUID } = useAuth();
  const { handleRepost, handleLike, handleDislike, handleDelete, handleReport } = usePublicationActions();

  const { loading, error, data: likedData, refetch } = useQuery(GET_LIKED_PUBLICATIONS, {
    variables: { userId: userUID },
    fetchPolicy: 'network-only',
  });

  const formatDate = (date) => {
    const now = moment();
    const publicationDate = moment(date);
    const diffInMinutes = now.diff(publicationDate, 'minutes');
    const diffInHours = now.diff(publicationDate, 'hours');
    const diffInDays = now.diff(publicationDate, 'days');

    if (diffInMinutes < 1) {
      return '1 min';
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} mins`;
    } else if (diffInHours < 24) {
      return `${diffInHours} h`;
    } else {
      return `${diffInDays} d`;
    }
  };

  const handleLikeAction = async (publicationId) => {
    await handleLike(publicationId); // Suppose que handleLike met à jour le statut aimé
    refetch(); // Rafraîchit manuellement les publications aimées après un like
  };

  const handleDislikeAction = async (publicationId) => {
    await handleDislike(publicationId); // Suppose que handleDislike met à jour le statut désaimé
    refetch(); // Rafraîchit manuellement les publications aimées après un dislike
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading publications.</p>;

  const publications = likedData?.getLikedPublications.slice() || [];

  if (publications.length === 0) {
    return <p style={{ color: "#777" }}>Vous n'avez pas encore aimé de publication.</p>;
  }

  return (
    <>
      {publications
        .sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf())
        .map((publication, index) => (
          <FeedCard
            key={index}
            id={publication.originalPublicationId}
            avatar={publication.account ? publication.account.profilePicture || '/default-avatar.png' : '/default-avatar.png'}
            name={publication.account ? publication.account.fullName || 'Default Username' : 'Default Username'}
            username={publication.account ? publication.account.username || 'Default Username' : 'Default Username'}
            date={formatDate(publication.createdAt)}
            content={publication.content}
            photos={publication.gallery}
            originalPublicationDate={formatDate(publication.originalPublicationDate)}
            video={publication.video}
            url={publication.url}
            tag={publication.tag}
            onRepost={handleRepost}
            discount={publication.discount}
            slug={publication.slug}
            onLike={() => handleLikeAction(publication.originalPublicationId)}
            onDislike={() => handleDislikeAction(publication.originalPublicationId)}
            onDelete={handleDelete}
            onReport={handleReport}
            likedPublications={likedData ? likedData.getLikedPublications.map(pub => pub.originalPublicationId) : []}
            initialRepostCount={publication.repostCount}
            isLastPageCard={index === publications.length - 1}
            originalAuthor={publication.originalAuthor}
          />
        ))}
    </>
  );
};

export default Like;
