import styled from "@emotion/styled";

export const GridContainer = styled.div`
  display: grid;
  gap: 32px;

  @media (max-width: 768px) {
    gap: 24px;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const GridSettings = styled.div`
  display: grid;
  gap: 16px;

  @media (max-width: 768px) {
    gap: 24px;
  }
`;
