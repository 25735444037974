

import React, { useState } from 'react';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { CloudinaryContext, Image } from 'cloudinary-react';
import { Cloudinary } from 'cloudinary-core';

const cloudinaryCore = new Cloudinary({ cloud_name: 'dqdjbibmo' });

const Gallery = ({ onUpload }) => {
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async file => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = async ({ fileList: newFileList }) => {
    // Supprimer l'image de Cloudinary si elle est supprimée de la galerie
    const deletedFiles = fileList.filter(file => !newFileList.some(newFile => newFile.uid === file.uid));
    await Promise.all(deletedFiles.map(async file => {
      if (file.public_id) {
        try {
          await cloudinaryCore.api.delete_resources([file.public_id]);
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'image sur Cloudinary :', error);
        }
      }
    }));

    // Mettre à jour le state avec les fichiers filtrés
    setFileList(newFileList.slice(-6));

    // Appeler onUpload avec les fichiers originaux
    onUpload(newFileList.map(file => file.originFileObj));
  };

  return (
    <div>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={() => false} // Prevents automatic upload
      >
        {fileList.length >= 6 ? null : (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        )}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
      <CloudinaryContext cloudName="dqdjbibmo">
        {fileList.map(file => (
          <Image key={file.uid} publicId={file.url} width="100%" />
        ))}
      </CloudinaryContext>
    </div>
  );
};

export default Gallery;
