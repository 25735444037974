import { gql } from '@apollo/client';

export const GET_PUBLICATIONS_BY_USERNAME = gql`
  query GetPublicationsByUserName($username: String!) {
    getPublicationsByUserName(username: $username) {
      id
      content
      gallery {
        url
      }
      video
      url
      slug
      tag
      isRepost
      repostCount
      originalPublicationId
      createdAt
      account {
        fullName
        username
        profilePicture
        userUID
      }
      originalAuthor {
        id
        fullName
        username
        profilePicture
      }
      discount {
        code
        percentage
        color
      }
    }
  }
`;

export const GET_LIKED_PUBLICATIONS = gql`
  query GetLikedPublications($userId: ID!) {
    getLikedPublications(userId: $userId) {
      originalPublicationId
    }
  }
`;
