import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';

// antd
import { BellOutlined } from '@ant-design/icons';
import { Badge, Button, Avatar, Dropdown, Menu, Typography, Space, Modal } from 'antd';
import "./Styles/index.scss";

// graphql
import { useQuery, useMutation } from '@apollo/client';
import { GET_NOTIFICATIONS_BY_USER_UID, MARK_ALL_NOTIFICATIONS_READ } from "./qm";

// useAuth - AuthContext
import { useAuth } from '../AuthContext';

const { Title } = Typography;

const Notification = () => {
  const navigate = useNavigate();
  const { userUID } = useAuth();
  const { loading, error, data, refetch } = useQuery(GET_NOTIFICATIONS_BY_USER_UID, {
    variables: { userUID },
  });

  const [markAllNotificationsRead] = useMutation(MARK_ALL_NOTIFICATIONS_READ);
  const [notificationData, setNotificationData] = useState(null);
  const [visibleNotificationsCount, setVisibleNotificationsCount] = useState(5);
  const selectedLanguage = localStorage.getItem("preferredLanguage") || "fr";
  const [show, setShow] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    moment.locale(selectedLanguage);
  }, [selectedLanguage]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch({ userUID });
    }, 5000);

    return () => clearInterval(interval);
  }, [userUID, refetch]);

  useEffect(() => {
    if (data) {
      setNotificationData(data);
      updateShowState(data);
    }
  }, [data]);

  useEffect(() => {
    const handleScroll = () => {
      if (dropdownVisible) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [dropdownVisible]);

  const handleDropdownVisibleChange = async (visible) => {
    if (visible) {
      await markAllNotificationsRead({ variables: { userUID } });
      refetch({ userUID });
    }
    setDropdownVisible(visible);
  };

  const updateShowState = (data) => {
    if (data) {
      const hasImportantNotifications = data.getNotificationsByUserUID.some(notification => {
        return !notification.isRead;
      });
      setShow(hasImportantNotifications);
    }
  };

  const handleLoadMore = () => {
    setVisibleNotificationsCount(prevCount => prevCount + 5);
  };

  const renderNotifications = () => {
    if (!notificationData) return null;

    const groupedNotifications = {};

    notificationData.getNotificationsByUserUID.forEach(notification => {
      const date = moment(notification.createdAt).format('YYYY-MM-DD');
      if (!groupedNotifications[date]) {
        groupedNotifications[date] = [];
      }
      groupedNotifications[date].push(notification);
    });

    Object.keys(groupedNotifications).forEach(date => {
      groupedNotifications[date].sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf());
    });

    const sortedDates = Object.keys(groupedNotifications).sort(
      (a, b) => moment(b).valueOf() - moment(a).valueOf()
    );

    return sortedDates.map(date => (
      <div key={date}>
        <h4 className="title-moment">
          {moment(date).calendar(null, {
            sameDay: selectedLanguage === "fr" ? '[Aujourd’hui]' : '[Today]',
            lastDay: selectedLanguage === "fr" ? '[Hier]' : '[Yesterday]',
            sameElse: 'dddd',
          })}
        </h4>
        {groupedNotifications[date].slice(0, visibleNotificationsCount).map(notification => (
          <Button
            key={`${notification.userUID}-${notification.createdAt}`}
            type="link"
            onClick={(e) => {
              e.preventDefault();
              navigate(notification.slug ? `/p/${notification.slug}` : `/${notification.followerUsername}`);
            }}
            className={`notification-item ${!notification.isRead ? 'unread-notification' : 'read-notification'}`}
          >
            <Space>
              <div>
                <Avatar src={notification.account?.profilePicture || 'default_avatar_url'} />
              </div>
              <div>
                <div className="notification-message">
                  {selectedLanguage === "fr" ? notification.message : notification.messageTranslator}
                </div>
                <div className="notification-date">
                  {moment(notification.createdAt).fromNow()}
                </div>
              </div>
            </Space>
          </Button>
        ))}
        {groupedNotifications[date].length > visibleNotificationsCount && (
          <Button type="link" onClick={handleLoadMore} className="ant-btn-view">
            {selectedLanguage === "fr" ? "Voir plus" : "Load More"}
          </Button>
        )}
      </div>
    ));
  };

  const notificationCount = notificationData
    ? notificationData.getNotificationsByUserUID.filter(notification => !notification.isRead).length
    : 0;

  const notificationMenu = (
    <Menu className="notification-menu">
      {renderNotifications()}
    </Menu>
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleButtonClick = () => {
    if (windowWidth <= 767) {
      setModalVisible(true);
      setDropdownVisible(false); // Ensure dropdown is closed on modal open
    } else {
      setDropdownVisible(!dropdownVisible);
      setModalVisible(false); // Ensure modal is closed on dropdown toggle
    }
  };
  

  return (
    <>
      <Dropdown
        placement="topRight"
        overlay={notificationMenu}
        onVisibleChange={handleDropdownVisibleChange}
        visible={dropdownVisible}
        trigger={['click']}
      >
        <Button type="link" style={{ position: 'relative', fontSize: "18px" }} onClick={handleButtonClick}>
          <Badge
            dot={show}
            style={{ backgroundColor: 'hwb(205 6% 9%)', color: '#fff', position: 'absolute', top: -2, right: -2 }}
          />
          <BellOutlined />
        </Button>
      </Dropdown>

      <Modal
        title="Notifications"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        {renderNotifications()}
      </Modal>
    </>
  );
};

export default Notification;
