import { gql } from '@apollo/client';

export const REPOST_PUBLICATION = gql`
  mutation RepostPublication($originalPublicationId: ID!, $userId: ID!) {
    repostPublication(originalPublicationId: $originalPublicationId, userId: $userId) {
      originalPublicationId
      userId
    }
  }
`;

export const LIKE_PUBLICATION = gql`
  mutation LikePublication($publicationId: ID!, $userId: ID!) {
    likePublication(publicationId: $publicationId, userId: $userId) {
      id
    }
  }
`;

export const DISLIKE_PUBLICATION = gql`
  mutation DislikePublication($publicationId: ID!, $userId: ID!) {
    unlikePublication(publicationId: $publicationId, userId: $userId) {
      id
    }
  }
`;

export const DELETE_PUBLICATION = gql`
  mutation DeletePublication($publicationId: ID!, $userId: ID!) {
    deletePublication(publicationId: $publicationId, userId: $userId)
  }
`;

export const REPORT_PUBLICATION = gql`
  mutation ReportPublication($publicationId: ID!, $userId: String!, $reason: String!) {
    reportPublication(publicationId: $publicationId, userId: $userId, reason: $reason) {
      success
      message
    }
  }
`;
