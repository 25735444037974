import React, { useState, useEffect } from "react";
import CustomLayout from "../../components/Layout";
import { Card, Button, Modal, Alert } from "antd";
import LanguageSelector from "../../LanguageSelector";
import NavigationLegal from "../../components/Footer";
import { GridContainer, Flex, GridSettings } from "./Styles";
// import ThemeSwitcher from "../../components/ThemeSwitcher";
import { useAuth } from "../../components/User/AuthContext";
import { useMutation } from "@apollo/client";
import { DELETE_ACCOUNT } from "./qm"; // Assurez-vous d'importer correctement votre mutation
import { Link } from "react-router-dom";

function Settings() {
  const { isAuthenticated, userUID, logout } = useAuth();
  const selectedLanguage = localStorage.getItem("preferredLanguage") || "fr";

  // Définition des textes selon la langue sélectionnée
  const ACCOUNT_SETTINGS_TITLE = selectedLanguage === "fr" ? "Réglages" : "Settings";
  const ACCOUNT_SETTINGS_DELETE_TITLE = selectedLanguage === "fr" ? "Réglages du compte" : "Account Settings";
  const DELETE_MY_ACCOUNT = selectedLanguage === "fr" ? "Supprimer mon compte" : "Delete my account";
  const CONFIRM_DELETE = selectedLanguage === "fr" ? "Confirmation de la suppression du compte" : "Account deletion confirmation";
  const CONFIRM_MESSAGE = selectedLanguage === "fr" ? "Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible et supprimera tous vos posts, commentaires, relations de suivi et notifications." : "Are you sure you want to delete your account? This action is irreversible and will delete all your posts, comments, tracking relationships and notifications.";
  const CANCEL_DELETE = selectedLanguage === "fr" ? "Annuler" : "Cancel";
  const YES_DELETE = selectedLanguage === "fr" ? "Confirmer" : "Confirm";
  const THEMES = selectedLanguage === "fr" ? "Thème blanc bientôt disponible" : "White theme coming soon";
  const VERSION = selectedLanguage === "fr" ? "v1.0 - Dernière mise à jour le 05/07/2024" : "v1.0 - Last updated on 07/05/2024";
  const FEEDBACK = selectedLanguage === "fr" ? "Soumettre votre avis" : "Send your Feedback";

  const [, setIsMobile] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [deleteAccount, { loading, error, data }] = useMutation(
    DELETE_ACCOUNT
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDeleteAccount = async () => {
    try {
      const response = await deleteAccount({
        variables: { userUID },
      });
      console.log(response.data);
      setDeleteModalVisible(false);
      logout();
    } catch (error) {
      console.error("Erreur lors de la suppression du compte", error);
    }
  };

  const handleModalCancel = () => {
    setDeleteModalVisible(false);
  };

  const showDeleteModal = () => {
    setDeleteModalVisible(true);
  };

  return (
    <CustomLayout>
      <GridContainer>
        <div>
          <Card
            style={{marginBottom: 24}}
            title={ACCOUNT_SETTINGS_TITLE}
            extra={
              <Flex>
                <NavigationLegal />
              </Flex>
            }
          >
            <GridSettings>
{/*
<ThemeSwitcher />
*/}
<p style={{color: "#777", margin: 0, fontSize: 16}}>
  
  {THEMES}
  
  </p>         


  <Button type="primary"
 href="https://airtable.com/appY5UQpDXpjzI3lz/paggwcfKjJShuyoLD/form" target="_blank">{FEEDBACK}</Button>
  


  


  


  
  <Alert message={VERSION} type="info" showIcon />


  
       <LanguageSelector />
            </GridSettings>
          </Card>

          {isAuthenticated && (
            <Card
              title={ACCOUNT_SETTINGS_DELETE_TITLE}
            >
              <GridSettings>
                <Button style={{width: "max-content"}} type="default" onClick={showDeleteModal}>
                  {DELETE_MY_ACCOUNT}
                </Button>
              </GridSettings>
            </Card>
          )}
        </div>
      </GridContainer>

      <Modal
        title={CONFIRM_DELETE}
        visible={deleteModalVisible}
        onOk={handleDeleteAccount}
        onCancel={handleModalCancel}
        okText={YES_DELETE}
        cancelText={CANCEL_DELETE}
      >
        {CONFIRM_MESSAGE}
      </Modal>
    </CustomLayout>
  );
}

export default Settings;
