import { gql } from '@apollo/client';

export const GET_PUBLICATIONS_BY_TAG = gql`
  query GetPublicationsByTag($tag: String!) {
    getPublicationsByTag(tag: $tag) {
      id
      content
      gallery {
        url
      }
      video
      url
      slug
      isRepost
      repostCount
      originalPublicationId
      createdAt
      account {
        fullName
        username
        profilePicture
        userUID
      }
      originalAuthor {
        id
        fullName
        username
        profilePicture
      }
      discount {
        code
        percentage
        color
      }
      tag
    }
  }
`;

export const GET_LIKED_PUBLICATIONS = gql`
  query GetLikedPublications($userId: ID!) {
    getLikedPublications(userId: $userId) {
      originalPublicationId
    }
  }
`;
