import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import { REPOST_PUBLICATION, LIKE_PUBLICATION, DISLIKE_PUBLICATION, DELETE_PUBLICATION, REPORT_PUBLICATION } from './qm'; // Assurez-vous que ces imports sont corrects
import { useAuth } from '../../components/User/AuthContext';

const usePublicationActions = () => {
  const { userUID } = useAuth();
  
  const [repostPublication] = useMutation(REPOST_PUBLICATION);
  const [likePublication] = useMutation(LIKE_PUBLICATION);
  const [dislikePublication] = useMutation(DISLIKE_PUBLICATION);
  const [deletePublication] = useMutation(DELETE_PUBLICATION);
  const [reportPublication] = useMutation(REPORT_PUBLICATION);

  const handleRepost = async (id) => {
    try {
      const variables = { originalPublicationId: id, userId: userUID };
      await repostPublication({ variables });
    } catch (error) {
      console.error('Error reposting publication:', error.message);
      notification.error({
        message: 'Error',
        description: `Error reposting publication: ${error.message}`,
      });
    }
  };

  const handleLike = async (id) => {
    try {
      const variables = { publicationId: id, userId: userUID };
      await likePublication({ variables });
    } catch (error) {
      console.error('Error liking publication:', error.message);
    }
  };

  const handleDislike = async (id) => {
    try {
      const variables = { publicationId: id, userId: userUID };
      await dislikePublication({ variables });
    } catch (error) {
      console.error('Error disliking publication:', error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const variables = { publicationId: id, userId: userUID };
      await deletePublication({ variables });
      notification.success({
        message: 'Delete Successful',
        description: 'Publication deleted successfully!',
      });
    } catch (error) {
      console.error('Error deleting publication:', error.message);
      notification.error({
        message: 'Error',
        description: `Error deleting publication: ${error.message}`,
      });
    }
  };

  const handleReport = async (id, reason) => {
    try {
      const variables = { publicationId: id, userId: userUID, reason };
      await reportPublication({ variables });
      notification.success({
        message: 'Report Successful',
        description: 'Publication reported successfully!',
      });
    } catch (error) {
      console.error('Error reporting publication:', error.message);
      notification.error({
        message: 'Error',
        description: `Error reporting publication: ${error.message}`,
      });
    }
  };

  return {
    handleRepost,
    handleLike,
    handleDislike,
    handleDelete,
    handleReport,
  };
};

export default usePublicationActions;
