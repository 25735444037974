import React from 'react';
import { Form, Input, Button, notification, Alert, Typography } from 'antd';
import { useMutation, gql } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import CustomLayout from "../../Layout";

const { Title, Paragraph } = Typography;


// Définir la mutation GraphQL
const CREATE_ACCOUNT = gql`
  mutation CreateAccount($input: AccountInput!) {
    createAccount(input: $input) {
      fullName
      username
      email
      password
      userUID
      generatedPassword
    }
  }
`;

const generatePassword = () => {
  return Math.random().toString(36).slice(-8);
};

const CreateAccountForm = () => {
  const selectedLanguage = localStorage.getItem("preferredLanguage") || "fr";
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [createAccount, { loading, error, data }] = useMutation(CREATE_ACCOUNT);

// Fonction pour valider le mot de passe
// Fonction pour valider le mot de passe
const validatePassword = async (_, value) => {
  // Mettez en place vos règles de validation ici
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  if (!value || value.length < 8) {
    return Promise.reject(
      selectedLanguage === "fr" ? 'Le mot de passe doit contenir au moins une minuscule, une majuscule, un chiffre et un caractère spécial (@$!%*?&)!' : 'Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character (@$!%*?&)!'
    );
  }

  if (!passwordRegex.test(value)) {
    return Promise.reject(
      selectedLanguage === "fr" ? 'Le mot de passe doit contenir au moins une minuscule, une majuscule, un chiffre et un caractère spécial (@$!%*?&)!' : 'Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character (@$!%*?&)!'
    );
  }

  return Promise.resolve();
};


  const onFinish = async (values) => {
    const userUID = uuidv4();
    const generatedPassword = generatePassword();

    const input = {
      ...values,
      userUID,
      generatedPassword,
    };

    try {
      const response = await createAccount({ variables: { input } });
      console.log('Mutation Response:', response);
      notification.success({
        message: selectedLanguage === "fr" ? 'Compte créé avec succès' : 'Account created successfully',
        description: selectedLanguage === "fr" ? 'Votre compte a été créé avec succès. Vous pouvez maintenant vous connecter.' : 'Your account has been successfully created. You can now log in.',
      });
      navigate('/u/login');
    } catch (err) {
      console.error('Mutation Error:', err);
      if (err.message.includes('USERNAME_ALREADY_EXISTS')) {
        notification.error({
          message: selectedLanguage === "fr" ? 'Erreur de création de compte' : 'Account creation error',
          description: selectedLanguage === "fr" ? 'Le nom d\'utilisateur est déjà pris. Veuillez en choisir un autre.' : 'The username is already taken. Please choose another one.',
        });
      } else {
        notification.error({
          message: selectedLanguage === "fr" ? 'Erreur de création de compte' : 'Account creation error',
          description: selectedLanguage === "fr" ? 'Une erreur est survenue lors de la création de votre compte. Veuillez réessayer.' : 'An error occurred while creating your account. Please try again.',
        });
      }
    }
  };

  return (
    <CustomLayout>
      <Title style={{ marginBottom: 5 }} level={2}>{selectedLanguage === "fr" ? "S'inscrire" : "Register"}</Title>
      <Paragraph style={{ fontSize: 16, color: "#777", marginBottom: 16 }}>{selectedLanguage === "fr" ? "Créer un compte, publier et interagir avec Sendkr." : "Create an account, publish, and interact with Sendkr"}</Paragraph>
      <Form
        form={form}
        name="create_account"
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          name="fullName"
          label={selectedLanguage === "fr" ? "Nom du profil" : "Profile Name"}
          rules={[{ required: true, message: selectedLanguage === "fr" ? "Veuillez entrer le nom de votre profil!" : "Please enter your profile name!" }]}

        >
          <Input size="large" placeholder={selectedLanguage === "fr" ? "Ma boutique" : "Compagny"} />
        </Form.Item>

        <Form.Item
          name="username"
          label={selectedLanguage === "fr" ? "Nom d'utilisateur" : "Username"}
          rules={[{ required: true, message: selectedLanguage === "fr" ? 'Veuillez entrer le nom d\'utilisateur!' : 'Please enter the username!' }]}
        >
          <Input size="large" placeholder={selectedLanguage === "fr" ? "maboutique" : "mycompagny"} />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, type: 'email', message: selectedLanguage === "fr" ? 'Veuillez entrer un email valide!' : 'Please enter a valid email!' }]}
        >
          <Input size="large" placeholder={selectedLanguage === "fr" ? "contact@votre-entreprise.com" : "contact@mycompagny.com"} />
        </Form.Item>

        <Form.Item
          name="password"
          label={selectedLanguage === "fr" ? "Mot de passe" : "Password"}
          rules={[
            { required: true, message: selectedLanguage === "fr" ? 'Veuillez entrer le mot de passe!' : 'Please enter the password!' },
            { validator: validatePassword }
          ]}
        >
          <Input.Password size="large" placeholder={selectedLanguage === "fr" ? "Mot de passe" : "Password"} />
        </Form.Item>

        <Form.Item>
          <Button size="large" type="primary" htmlType="submit" loading={loading}>
            {selectedLanguage === "fr" ? "Créer un compte" : "Create an account"}
          </Button>
        </Form.Item>

        {error && <Alert message={error.message} type="error" showIcon />}
      </Form>
    </CustomLayout>
  );
};

export default CreateAccountForm;
