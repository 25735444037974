import styled from "@emotion/styled";

export const NavigationContainer = styled.div`
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  border: 1px solid #1e1e1e;
  border-radius: 50rem;
  max-width: 300px;
  background-color: #1e1e1e;
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);

  .navigation {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #999;

    a {
      color: #999;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
    }

    span {
      font-weight: normal;
    }

    .nav-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 15px;
    }
  }

  @media (max-width: 768px) {
    bottom: 0;
    left: 0;
    transform: translateX(0%);
    min-width: 100%;
    border-radius: 0;
    border-top: 1px solid #1e1e1e;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
    padding: 16px;
  }
`;
