import { gql } from '@apollo/client';

// Définition de la requête GraphQL avec paramètre userUID
export const GET_FOLLOWERS = gql`
  query GetFollowers($userUID: String!) {
    getFollowers(userUID: $userUID) {
      userUID
      username
      profilePicture
    }
  }
`;
