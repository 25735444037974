import React, { useEffect, useState } from 'react';
import { Typography, Tabs } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_PUBLICATIONS_BY_TAG, GET_LIKED_PUBLICATIONS } from './qm';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../components/User/AuthContext';
import moment from 'moment';

import CustomLayout from '../../components/Layout';
import FeedCard from '../Feed/FeedCard';
import Like from '../Like';
import usePublicationActions from '../hookp/usePublicationActions';

const { Title } = Typography;
const { TabPane } = Tabs;

const Tag = () => {
  const { tag } = useParams(); // Récupérer le paramètre 'tag' depuis l'URL
  const { isAuthenticated, userUID } = useAuth();
  const [currentTag, setCurrentTag] = useState(tag); // Utiliser currentTag à la place de currentUsername
  const { handleRepost, handleLike, handleDislike, handleDelete, handleReport } = usePublicationActions();

  const { loading: likedLoading, error: likedError, data: likedData } = useQuery(GET_LIKED_PUBLICATIONS, {
    variables: { userId: userUID },
    fetchPolicy: 'cache-first',
  });

  const selectedLanguage = localStorage.getItem("preferredLanguage") || "fr";

  useEffect(() => {
    setCurrentTag(tag); // Mettre à jour currentTag lorsque le tag change
  }, [tag]);

  const { data, loading, error } = useQuery(GET_PUBLICATIONS_BY_TAG, {
    variables: { tag: currentTag }, // Utiliser currentTag pour effectuer la requête
    fetchPolicy: 'cache-first',
  });

  const formatDate = (date) => {
    const now = moment();
    const publicationDate = moment(date);
    const diffInMinutes = now.diff(publicationDate, 'minutes');
    const diffInHours = now.diff(publicationDate, 'hours');
    const diffInDays = now.diff(publicationDate, 'days');

    if (diffInMinutes < 1) {
      return '1 min';
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} mins`;
    } else if (diffInHours < 24) {
      return `${diffInHours} h`;
    } else {
      return `${diffInDays} j`;
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading publications.</p>;

  const publications = data.getPublicationsByTag.slice();

  return (
    <CustomLayout>
      <Title level={4}>#{currentTag}</Title>

      {publications
        .sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf())
        .map((publication, index) => (
          <FeedCard
            key={index}
            id={publication.id}
            avatar={publication.account ? publication.account.profilePicture || '/default-avatar.png' : '/default-avatar.png'}
            name={publication.account ? publication.account.fullName || 'Default Username' : 'Default Username'}
            username={publication.account ? publication.account.username || 'Default Username' : 'Default Username'}
            date={formatDate(publication.createdAt)}
            content={publication.content}
            photos={publication.gallery}
            originalPublicationDate={formatDate(publication.originalPublicationDate)}
            video={publication.video}
            url={publication.url}
			tag={publication.tag}
            account={publication.account ? publication.account.userUID || 'userUID' : 'userUID'}
            onRepost={handleRepost}
            discount={publication.discount}
            slug={publication.slug}
            onLike={handleLike}
            onDislike={handleDislike}
            onDelete={handleDelete}
            onReport={handleReport}
            likedPublications={likedData ? likedData.getLikedPublications.map(pub => pub.originalPublicationId) : []}
            initialRepostCount={publication.repostCount}
            isLastPageCard={index === publications.length - 1}
            originalAuthor={publication.originalAuthor}
          />
        ))}
    </CustomLayout>
  );
};

export default Tag;
