import React, { useEffect, useState } from "react";
import { HeaderDisplay, HeaderDisplayContent, FlexStyles, Div } from "./Styles";
import UserAuthenticated from "../User/Auth";
import { useAuth } from "../User/AuthContext";
import Notification from "../User/Notification";

const Header = () => {
  const { isAuthenticated } = useAuth(); // Utilisez le hook d'authentification
  const selectedLanguage = localStorage.getItem("preferredLanguage") || "fr";
  const [theme, setTheme] = useState("dark");

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme");
    if (currentTheme) {
      setTheme(currentTheme);
    }
  }, []);

  const backgroundColor = theme === "dark" ? "#101010e4" : "#FFF";

  return (
    <div>
      <header style={{ ...HeaderDisplay, backgroundColor: backgroundColor }}>
        <div style={HeaderDisplayContent}>
          <div style={{ ...FlexStyles, ...Div.menu }}>
            <img style={{ maxWidth: 50 }} src="https://res.cloudinary.com/helpquit/image/upload/v1714598651/vectorpaint_kbdod3.svg" alt="Logo" />
            <div style={{ flexGrow: 1 }}></div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {isAuthenticated && <Notification />}
            <UserAuthenticated lang={selectedLanguage} />
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
