export const HeaderDisplay = {
  padding: "0",
  maxWidth: "1240px",
  margin: "0 auto",
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: 80,
  top: "0",
  left: "0",
  right: 0,
  zIndex: "999",
  backdropFilter: "blur(32px)",
  WebkitBackdropFilter: "blur(32px)",
  alignItems: "center",
};

export const HeaderDisplayContent = {
  display: "flex",
  justifyContent: "space-between",
  marginInlineStart: "20px", // Ajout de la propriété CSS 'margin-inline-start'
  marginInlineEnd: "20px", // Ajout de la propriété CSS 'margin-inline-end'
  width: "100%"
};

export const FlexStyles = {
  display: "flex",
  alignItems: "center",
  gap: 7,
};

export const Div = {
  menu: {
    display: "flex",
    alignItems: "center",

  },
};
