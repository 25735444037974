import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "../../../components/User/AuthContext";

import HeaderCard from "./HeaderCard";
import FeedGallery from "./FeedGallery";
import ResponsiveVideo from "./Video";

import { HeartOutlined, EllipsisOutlined, HeartFilled, CommentOutlined, RetweetOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Modal, Select, Dropdown, Menu, message, notification } from "antd";
import { Linked, CardContainer, ContentContainer as OriginalContentContainer, Count } from "./Styles/StylesCard";
import { BiRepost } from "react-icons/bi";
import "./Styles/StylesCard.scss";
import styled from "@emotion/styled";
import ColorThief from 'colorthief';



const ContentContainer = styled(OriginalContentContainer)`
  padding: ${({ isRepost }) => (isRepost ? "16px" : "initial")};
  border-radius: ${({ isRepost }) => (isRepost ? "12px" : "initial")};
    background-color: ${({ isRepost }) => (isRepost ? "#1e1e1e" : "none")};    

`;

const CardFeed = ({
  id,
  originalPublicationDate,
  username,
  content,
  account,
  avatar,
  date,
  tag,
  photos,
  video,
  url,
  slug,
  onReport, // Nouveau prop pour originalPublicationSlug
  isLastPageCard,
  discount,
  verified,
  domain,
  onRepost,
  onLike,
  onDislike,
  onDelete,
  originalAuthor,
  initialRepostCount,
  likedPublications
}) => {
  const { isAuthenticated, userUID } = useAuth();
  const [repostCount, setRepostCount] = useState(initialRepostCount);
  const [liked, setLiked] = useState(likedPublications.includes(id));
  const selectedLanguage = localStorage.getItem("preferredLanguage") || "fr";
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);
  const [reason, setReason] = useState(""); // Assurez-vous que reason est un état dans votre composant

  const [buttonColor, setButtonColor] = useState("#000000");
  const { Option } = Select;

  const handleOpenModal = () => {
    setModalVisible(true);
  };
  
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleSelectReason = (selectedReason) => {
    setReason(selectedReason); // Mettez à jour l'état reason lorsque l'utilisateur sélectionne une raison
  };

  useEffect(() => {
    const extractDominantColor = async () => {
      try {
        let src = null;

        if (photos && photos.length > 0) {
          src = photos[0].url;
        } else if (video) {
          src = video;
        }

        if (src) {
          const colorThief = new ColorThief();
          const imageElement = new Image();
          imageElement.crossOrigin = "Anonymous";
          imageElement.src = src;

          imageElement.onload = async () => {
            // Get the color palette from the image
            const palette = await colorThief.getPalette(imageElement, 8); // Adjust the number of colors as needed
            let dominantColor = palette[0]; // Default to the first color in the palette

            // Find a color that is not too light
            for (let color of palette) {
              const brightness = (color[0] * 299 + color[1] * 587 + color[2] * 114) / 1000;
              if (brightness >= 128) {
                continue; // Skip colors that are too light
              }
              // Check if the color has significant green component
              if (color[1] > color[0] && color[1] > color[2]) {
                dominantColor = color; // Choose the first green-dominant color found
                break;
              }
            }

            // Set the button color
            setButtonColor(`rgb(${dominantColor[0]}, ${dominantColor[1]}, ${dominantColor[2]})`);
          };
        }
      } catch (error) {
        console.error('Error extracting color:', error);
      }
    };

    extractDominantColor();
  }, [photos, video]);

  const handleRepost = async () => {
    if (!isAuthenticated) {
      console.error('User is not authenticated');
      return;
    }

    try {
      await onRepost(id);
      setRepostCount(repostCount + 1);
      notification.success({
        message: 'Publication Reposted',
        description: 'The publication has been reposted successfully!',
        placement: 'topLeft'
      });
    } catch (error) {
      console.error('Error reposting publication:', error);
    }
  };

  const handleLike = async () => {
    if (!isAuthenticated) {
      console.error('User is not authenticated');
      return;
    }

    try {
      await onLike(id);
      setLiked(!liked);
      // You can add logic here to update like count or perform any other actions related to liking a post
    } catch (error) {
      console.error('Error liking publication:', error);
    }
  };

  const handleDislike = async () => {
    if (!isAuthenticated) {
      console.error('User is not authenticated');
      return;
    }

    try {
      await onDislike(id);
      setLiked(false); // Set liked state to false
    } catch (error) {
      console.error('Error disliking publication:', error);
    }
  };

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      message.success('Code promo copié avec succès');
    }).catch((error) => {
      message.error('Échec de la copie du code promo');
      console.error('Erreur lors de la copie du code promo : ', error);
    });
  };


  const extractDomain = (url) => {
    try {
      let hostname = new URL(url).hostname;
  
      // Remove 'www.'
      if (hostname.startsWith('www.')) {
        hostname = hostname.slice(4);
      }
  
      // Split by '.' and take the last two parts to get the main domain
      const parts = hostname.split('.');
      if (parts.length > 2) {
        hostname = parts.slice(-2).join('.');
      }
  
      return hostname;
    } catch (error) {
      console.error("Invalid URL:", error.message);
      return null; // Or any other default value or handling
    }
  };

  const domainUrl = extractDomain(url);
  const faviconUrl = `https://www.google.com/s2/favicons?domain=${domainUrl}&sz=128`;  

  const handleDelete = async () => {
    try {
      await onDelete(id);
      console.log(id);
    
    } catch (error) {
      console.error('Error deleting publication:', error);
   
    }
  };

  const handleReport = async () => {
    try {
      if (!reason) {
        message.error("Veuillez sélectionner un motif de signalement.");
        return;
      }
  
      // Utilisation de l'état reason mis à jour
      await onReport(id, userUID, reason);
  
      notification.success({
        message: selectedLanguage === "fr" ? "Publication Signalée" : "Reported publication",
        description: selectedLanguage === "fr" ? "La publication a été signalée avec succès !" : "The publication was successfully reported!",
        placement: "topLeft",
      });
  
      handleCloseModal();
    } catch (error) {
      console.error(selectedLanguage === "fr" ? "Erreur" : "Error", error);
      notification.error({
        message: "Erreur",
        description: "Une erreur est survenue lors du signalement de la publication.",
        placement: "topLeft",
      });
    }
  };
  

  const menu = (
    <Menu>
   <Menu.Item key="1" onClick={handleOpenModal}>
        {selectedLanguage === "fr" ? "Signaler" : "Report"}

    </Menu.Item>
    {userUID === account || userUID === 'userUID' ? (
        <Menu.Item key="2" onClick={handleDelete}>
          Supprimer la publication 
        </Menu.Item>
      ) : null}
 
    </Menu>
  );

  return (
    <CardContainer isLastPageCard={isLastPageCard} isRepost={!!originalAuthor}>
      <div className="item-brand">
        <div className="item-brand-info">
          <div>
       
            {avatar && (
              <Avatar className="user-avatar" src={avatar} alt="Avatar" size={38} />
            )}
          </div>
          <HeaderCard 
            content={originalAuthor ? <></> : content} 
            username={username} 
            verified={verified} 
            domain={domain} 
            date={date} 
            tag={tag} 

          />
        </div>


        <Dropdown overlay={menu} trigger={['click']}>
          <Button type="link" icon={<EllipsisOutlined style={{ fontSize: 21, color: "#777" }} />} />
        </Dropdown>
      </div>

      <ContentContainer isRepost={!!originalAuthor}>
        {originalAuthor && (
          <div className="item-brand">
            <div className="item-brand-info">
              <div>



              <Avatar className="user-avatar" src={originalAuthor.profilePicture} alt="Avatar" size={32} />



</div>
              <HeaderCard
                content={content}
                username={originalAuthor.username}
                verified={verified}
                domain={domain}
                date={originalPublicationDate}
              />
            </div>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button type="link" icon={<EllipsisOutlined style={{ fontSize: 21, color: "#777" }} />} />
            </Dropdown>
          </div>
        )}


          
        {video && <ResponsiveVideo video={video} />}
        {photos && <FeedGallery images={photos.map(photo => ({ src: photo.url }))} />}



        {discount && discount.code && discount.percentage && (
  <div>
    <Card bordered={true}   
    >



<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}> 



<div style={{display: "flex", gap: 7}}>       
  
  
  <div>   <span style={{fontWeight: "bold"}}>{discount.percentage} % OFF</span>
  </div>
<div> <span>CODE</span><span>{discount.code}</span></div>
</div>





<div>


<Button 
  size="small"
  style={{ backgroundColor: buttonColor, borderColor: buttonColor, borderRadius: "6px" }}  type="default" 
  onClick={() => handleCopy(discount.code)}
>
  {selectedLanguage === "fr" ? "Copier" : "Copy"}
</Button>
      </div>
      
      
      </div>




    </Card>
  </div>
)}










      
        {isAuthenticated && 

        <div>
        <div style={{ display: "flex", gap: 7, alignItems: "center", justifyContent: "space-between" }}>



<div style={{ display: "flex", gap: 7, alignItems: "center", justifyContent: "space-between" }}> 



          <div>
          <Button type="link" style={{padding: 0}} onClick={liked ? handleDislike : handleLike}>
                {liked ? <HeartFilled style={{ fontSize: 19, color: "#ff375f" }} /> : <HeartOutlined style={{ fontSize: 17, color: "#FFF" }} />} 


              </Button>
          </div>





          <div>
          <Button
  type="link"
  style={{ padding: 0 }}
  icon={<CommentOutlined style={{ fontSize: 17, color: "#FFF" }} />}
  onClick={(e) => {
    e.preventDefault(); // Empêche le comportement par défaut du lien
    // Utilisation de originalPublicationSlug si c'est un repost, sinon utilise slug
    navigate("/p/" + (slug));
  }}
/>

          </div>






          <div>

          <Button type="link" style={{ padding: 0 }} onClick={handleRepost} >

          <BiRepost style={{fontSize: 24.5, color: "#FFF"}} />
          <span style={{color: "#777", marginLeft: 7}}>{repostCount} </span>

          

          </Button>

          </div>

          </div>



          <div style={{ display: "flex", alignItems: "center", gap: 7 }}>
            <div>
              <Linked target="_blank" href={url} className="item-brand-product-url">
                 {domainUrl}
              </Linked>
            </div>
          
          </div>



        </div>  
      </div>}
      </ContentContainer>

      <Modal
  title={selectedLanguage === "fr" ? 'Raison du signalement' : 'Reason for reporting'}
  visible={modalVisible}
  onCancel={handleCloseModal}
  footer={[

    <div className="report-flex">

    <Button key="cancel" onClick={handleCloseModal}>
            {selectedLanguage === "fr" ? 'Annuler' : 'Cancel'}

    </Button>
    <Button key="report" type="primary" onClick={handleReport}>
      {selectedLanguage === "fr" ? 'Envoyer' : 'Send'}
    </Button>
    
    
    </div>
    ,
  ]}
>
  <Select
    placeholder={selectedLanguage === "fr" ? 'Raison du signalement' : 'Reason for reporting'}
    style={{ width: "100%" }}
    onChange={handleSelectReason}
    value={reason}
  >
<Option value="Contenu inapproprié">{selectedLanguage === "fr" ? 'Contenu inapproprié' : 'Inappropriate content'}</Option>
<Option value="Spam">{selectedLanguage === "fr" ? 'Spam' : 'Spam'}</Option>
<Option value="Discours de haine">{selectedLanguage === "fr" ? 'Discours de haine' : 'Hate speech'}</Option>
<Option value="Harassment">{selectedLanguage === "fr" ? 'Harcèlement' : 'Harassment'}</Option>
<Option value="Bullying">{selectedLanguage === "fr" ? 'Cyberintimidation' : 'Bullying'}</Option>
<Option value="Fraud">{selectedLanguage === "fr" ? 'Fraude' : 'Fraud'}</Option>
<Option value="Violence">{selectedLanguage === "fr" ? 'Violence' : 'Violence'}</Option>
<Option value="Terrorism">{selectedLanguage === "fr" ? 'Terrorisme' : 'Terrorism'}</Option>
<Option value="False information">{selectedLanguage === "fr" ? 'Information fausse' : 'False information'}</Option>
<Option value="Phishing">{selectedLanguage === "fr" ? 'Phishing' : 'Phishing'}</Option>

  </Select>
</Modal>


    </CardContainer>
  );
};

CardFeed.propTypes = {
  id: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  originalPublicationSlug: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  account: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  video: PropTypes.string,
  photos: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string.isRequired })),
  isLastPageCard: PropTypes.bool.isRequired,
  verified: PropTypes.bool,
  domain: PropTypes.string.isRequired,
  onRepost: PropTypes.func,
  onLike: PropTypes.func, // Define onLike prop type
  onDislike: PropTypes.func, // Define onDislike prop type
  onDelete: PropTypes.func.isRequired,
  onReport: PropTypes.func.isRequired,
  originalAuthor: PropTypes.shape({
    id: PropTypes.string,
    username: PropTypes.string,
    profilePicture: PropTypes.string,
    originalPublicationDate: PropTypes.string,
  }),
  initialRepostCount: PropTypes.number,
  likedPublications: PropTypes.arrayOf(PropTypes.string),
  discount: PropTypes.shape({
    code: PropTypes.string,
    percentage: PropTypes.number,
    color: PropTypes.string,
  }),
};

export default CardFeed;
