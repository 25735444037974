import React, { useState } from 'react';
import { Modal, List, Avatar, Button } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_FOLLOWERS } from './qm'; // Importer la requête GraphQL
import { useNavigate } from 'react-router-dom'; // Importer useNavigate depuis react-router-dom

const GetFollowers = ({ userFollowersId, buttonText }) => {
  const [modalVisible, setModalVisible] = useState(false); // État pour gérer la visibilité du modal
  const navigate = useNavigate(); // Utiliser useNavigate pour la navigation

  // Exécuter la requête GraphQL avec useQuery
  const { loading, error, data } = useQuery(GET_FOLLOWERS, {
    variables: { userUID: userFollowersId }, // Utiliser userFollowersId comme userUID dans les variables
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <>
      <Button style={{ textAlign: "center", display: "block", width: "100%" }}  type="primary" onClick={() => setModalVisible(true)}>
        {buttonText || 'Open Followers'}
      </Button>
      <Modal
        title={buttonText}
        visible={modalVisible} // Utiliser l'état pour contrôler la visibilité
        onCancel={() => setModalVisible(false)} // Gérer la fermeture du Modal
        footer={null}
      >
        <List
          itemLayout="horizontal"
          dataSource={data.getFollowers}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={item.profilePicture} />}
                title={<span onClick={() => navigate(`/${item.username}`)}>{item.username}</span>}
              />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default GetFollowers;
