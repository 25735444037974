import { gql } from '@apollo/client';

export const GET_LIKED_PUBLICATIONS = gql`
  query GetLikedPublications($userId: ID!) {
    getLikedPublications(userId: $userId) {
      userId
      content
      gallery {
        url
      }
      video
      url
      slug
      tag
      createdAt
      isRepost
      repostCount
      originalPublicationId
      originalPublicationDate
      originalAuthor {
        username
      }
      account {
        fullName
        username
        profilePicture
      }
      discount {
        code
        percentage
        color
      }
    }
  }
`;