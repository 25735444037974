import React from "react";
import { Button } from "antd";
import { AuthS, Divider } from "./AuthStyles";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom"; // Importez useLocation
import { useAuth } from "../AuthContext";

const UserAuthenticated = () => {
  const { isAuthenticated, logout, isLoading } = useAuth();
  const selectedLanguage = localStorage.getItem("preferredLanguage") || "fr";

  // Définissez les textes en fonction de la langue sélectionnée
  const texts = {
    fr: {
      LOGIN_BUTTON_TEXT: "Se connecter",
      LOGOUT_BUTTON_TEXT: "Se déconnecter",
      REGISTER_BUTTON_TEXT: "S'inscrire",
    },
    en: {
      LOGIN_BUTTON_TEXT: "Log in",
      LOGOUT_BUTTON_TEXT: "Log out",
      REGISTER_BUTTON_TEXT: "Register",
    },
  };

  const {
    LOGIN_BUTTON_TEXT,
    LOGOUT_BUTTON_TEXT,
    REGISTER_BUTTON_TEXT,
  } = texts[selectedLanguage];

  const location = useLocation(); // Obtenez la route actuelle
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/u/login");
  };

  const handleRegister = () => {
    navigate("/u/register");
  };

  // Vérifiez si la route est /login ou /register
  const isLoginPage = location.pathname === "/u/login";
  const isRegisterPage = location.pathname === "/u/register";

  return (
    <AuthS>
      {/* Vérifiez si l'utilisateur est authentifié et que ce n'est pas la page de connexion */}
      {isAuthenticated && !isLoginPage && !isRegisterPage ? (
        <>
          <Button type="primary" onClick={logout}>
            {LOGOUT_BUTTON_TEXT}
          </Button>
        </>
      ) : (
        // Si ce n'est pas la page de connexion ou d'inscription, affichez le bouton de connexion et le bouton d'inscription
        <>
          <Button
            type="primary"
            onClick={isLoginPage ? handleRegister : handleLogin}
          >
            {isLoginPage ? REGISTER_BUTTON_TEXT : LOGIN_BUTTON_TEXT}
          </Button>
        </>
      )}
      {isLoading && (
        <Divider>
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        </Divider>
      )}
    </AuthS>
  );
};

export default UserAuthenticated;
