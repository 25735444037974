import { gql } from '@apollo/client';

export const GET_ACCOUNT_BY_UID = gql`
  query getAccountByUID($userUID: String!) {
    getAccountByUID(userUID: $userUID) {
      id
      fullName
      username
      email
      profilePicture
      biography
      websiteURL
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($id: ID!, $input: UpdateAccountInput!) {
    updateAccount(id: $id, input: $input) {
      id
      fullName
      profilePicture
      biography
      websiteURL
    }
  }
`;
