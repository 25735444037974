import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Form, Button, List, Input, Dropdown, Menu, message } from 'antd';
import { Comment } from "@ant-design/compatible";
import moment from 'moment';

import { GET_COMMENTS_BY_PUBLICATION_ID, CREATE_COMMENT, DELETE_COMMENT } from './mutations';
import { useAuth } from '../../../components/User/AuthContext';

const { TextArea } = Input;
const selectedLanguage = localStorage.getItem("preferredLanguage") || "fr";

const CommentList = ({ comments, deleteComment, userUID }) => {

  // Trie les commentaires par ordre chronologique inverse
  const sortedComments = [...comments].sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  return (
    <List
      dataSource={sortedComments}
      header={`${sortedComments.length} ${sortedComments.length > 1 ? 'comments' : 'comment'}`}
      itemLayout="horizontal"
      renderItem={comment => (
        <div style={{ borderBottom: "1px solid #1e1e1e", padding: "12px", backgroundColor: "#1e1e1e", borderRadius: 12, marginBottom: 6 }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center", gap: 7 }}>
              <div>
                {comment.account && comment.account.profilePicture ? (
                  <Avatar size="36px" src={comment.account.profilePicture} />
                ) : (
                  <Avatar icon="user" />
                )}
              </div>
              <div style={{ display: "flex", gap: 7 }}>
                <span> <strong>{comment.account ? comment.account.username : 'Unknown User'} </strong></span>
                <span style={{ color: "#777" }}>{moment(comment.createdAt).fromNow()}</span>
              </div>
            </div>
            <div>
              {comment.userId === userUID && (
                                  <Button  onClick={() => deleteComment(comment.id)} type="link">Supprimer</Button>

              )}
            </div>
          </div>
          <p style={{ margin: "0 0 0 40px" }}>{comment.content}</p>
        </div>
      )}
    />
  );
};


const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <div>
    <Form.Item>
      <TextArea rows={1} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
         {selectedLanguage === "fr" ? 'Ajouter un commentaire' : 'Add Comment'}
      </Button>
    </Form.Item>
  </div>
);

const CommentSection = ({ publicationId }) => {
  const [comments, setComments] = useState([]);
  const [submitComment] = useMutation(CREATE_COMMENT);
  const [deleteCommentMutation] = useMutation(DELETE_COMMENT);
  const [value, setValue] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const { userUID } = useAuth();

  const { loading, error, data } = useQuery(GET_COMMENTS_BY_PUBLICATION_ID, {
    variables: { publicationId },
  });

  useEffect(() => {
    if (data) {
      setComments(data.getCommentsByPublicationId);
    }
  }, [data]);

  const handleSubmit = async () => {
    if (!value) {
      return;
    }

    setSubmitting(true);

    try {
      const { data } = await submitComment({
        variables: {
          publicationId: publicationId,
          userId: userUID,
          content: value,
        },
        // Mise à jour du cache Apollo après la mutation réussie
        update: (cache, { data: { createComment } }) => {
          const existingComments = cache.readQuery({
            query: GET_COMMENTS_BY_PUBLICATION_ID,
            variables: { publicationId },
          });

          if (existingComments) {
            cache.writeQuery({
              query: GET_COMMENTS_BY_PUBLICATION_ID,
              variables: { publicationId },
              data: {
                getCommentsByPublicationId: [createComment, ...existingComments.getCommentsByPublicationId],
              },
            });
          }
        },
      });

      setValue('');
      setSubmitting(false);
    } catch (error) {
      console.error('Error creating comment: ', error);
      // Gestion des erreurs
    }
  };


  const handleDeleteComment = async (commentId) => {
    try {
      console.log("Comment ID:", commentId);
      console.log("User UID:", userUID);

      await deleteCommentMutation({
        variables: {
          commentId: commentId,
          userId: userUID,
        },
      });

      setComments(comments.filter(comment => comment.id !== commentId));

    } catch (error) {
      console.error('Erreur lors de la suppression du commentaire : ', error);
    }
  };

  const handleChange = e => {
    setValue(e.target.value);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <Comment
        content={<Editor onChange={handleChange} onSubmit={handleSubmit} submitting={submitting} value={value} />}
      />
      {comments.length > 0 && <CommentList comments={comments} deleteComment={handleDeleteComment} userUID={userUID} />}
    </div>
  );
};

export default CommentSection;

export { GET_COMMENTS_BY_PUBLICATION_ID, CREATE_COMMENT, DELETE_COMMENT };
