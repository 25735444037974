import React, { useEffect, useState } from "react";
import { NavigationContainer } from "./NavigationStyles";
import Level from "./Level";
import { GoHomeFill } from "react-icons/go";
import { IoMdAddCircleOutline } from "react-icons/io";
import { PiUserCircleGearLight } from "react-icons/pi";
import { CiSettings } from "react-icons/ci";
import { useAuth } from "../../User/AuthContext";
import { useQuery } from "@apollo/client";
import { GET_ACCOUNT_BY_UID } from "./qm";

const NavigationApp = ({ isActive }) => {
  const { isAuthenticated, userUID } = useAuth();
  const { loading, error, data } = useQuery(GET_ACCOUNT_BY_UID, {
    variables: { userUID },
    skip: !isAuthenticated,
  });

  const [, setVisible] = useState(false);
  const onClose = () => setVisible(false);

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme");
    if (currentTheme) {
      setTheme(currentTheme);
    }
  }, []);

  const [theme, setTheme] = useState("dark");
  const selectedLanguage = localStorage.getItem("preferredLanguage") || "fr";

  const menuTexts = {
    fr: {
      MENU_HOME: "Découvrir",
      MENU_QR_CODE: "Publier",
      MENU_SETTING: "Réglages",
      MENU_ACCOUNT: "Mon Compte",
    },
    en: {
      MENU_HOME: "Discover",
      MENU_QR_CODE: "Publish",
      MENU_SETTING: "Settings",
      MENU_ACCOUNT: "My Account",
    },
  };

  const texts = menuTexts[selectedLanguage];

  const backgroundColor = theme === "dark" ? "#101010e4" : "#FFF";

  // Gérer les états de chargement et d'erreur
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <NavigationContainer style={{ backgroundColor: backgroundColor }}>
      <div className="navigation">
        <Level
          to="/"
          onClick={onClose}
          isActive={isActive}
          icon={GoHomeFill}
        />
        {isAuthenticated && (
          <Level
            to="/u/create"
            onClick={onClose}
            isActive={isActive}
            icon={IoMdAddCircleOutline}
          />
        )}
        {isAuthenticated && (
          <Level
            to={`/u/${data?.getAccountByUID?.username}`}
            onClick={onClose}
            isActive={isActive}
            icon={PiUserCircleGearLight}
          />
        )}
        <Level
          to="/settings"
          onClick={onClose}
          isActive={isActive}
          icon={CiSettings}
        />
      </div>
    </NavigationContainer>
  );
};

export default NavigationApp;
