import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';

// Vérifier que l'URI est bien définie dans les variables d'environnement
const graphqlUri = process.env.REACT_APP_GRAPHQL_URI;
if (!graphqlUri) {
  throw new Error('La variable d\'environnement REACT_APP_GRAPHQL_URI doit être définie');
}

// Utiliser la variable d'environnement pour l'URI
const httpLink = createHttpLink({
  uri: graphqlUri,
});

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get('_scom_at_jwt1_'); // Utiliser Cookies.get au lieu de localStorage.getItem
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
