import React, { useState } from 'react';
import { Form, Input, InputNumber, ColorPicker, Button } from 'antd';

const Discount = ({ onDiscountChange }) => {
  const [showForm, setShowForm] = useState(false); // État pour contrôler l'affichage du formulaire
  const [code, setCode] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [color, setColor] = useState('#1677ff');
  const selectedLanguage = localStorage.getItem("preferredLanguage") || "fr";

  const handleCodeChange = (e) => {
    setCode(e.target.value);
    onDiscountChange({ code: e.target.value, percentage, color });
  };

  const handlePercentageChange = (value) => {
    setPercentage(value);
    onDiscountChange({ code, percentage: value, color });
  };

  const handleColorChange = (color) => {
    const colorValue = color.metaColor ? `rgba(${color.metaColor.r}, ${color.metaColor.g}, ${color.metaColor.b}, ${color.metaColor.a})` : '';
    setColor(colorValue);
    onDiscountChange({ code, percentage, color: colorValue });
  };

  const handleToggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <div>
      <Button onClick={handleToggleForm}>   {selectedLanguage === "fr" ? 'Ajouter un code de réduction' : 'Add a discount code'}   </Button>
      {showForm && (
        <Form layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}  style={{marginTop: 24}}>
          <Form.Item label="Code de réduction" required>
            <Input placeholder="Code de réduction" value={code} onChange={handleCodeChange} />
          </Form.Item>
          <Form.Item label="%" required>
            <InputNumber
              min={0}
              max={100}
              value={percentage}
              onChange={handlePercentageChange}
            />
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default Discount;
