import styled from "@emotion/styled";

export const Card = styled.div`
  padding-bottom: 14px;
  border-bottom: 1px solid #1e1e1e;
`;

export const Report = styled.div`
  /* Add your styles for Report component here */
`;

export const HeaderInformations = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0px;
`;

export const InformationsContainer = styled.div`
  display: grid;
  gap: 5.5px;
`;

export const CardContainer = styled.div`
margin-bottom: 0;
  border-bottom: 1px solid #2d2d2d;
  border-radius: 0;
  padding: 16px;

@media (max-width: 768px) {
  margin-bottom: 0;
  border-bottom: 1px solid #2d2d2d;
  border-radius: 0;
  padding: 16px;
  border-left: 0;
  border-right: 0;
}





`;

export const Informations = styled.h4`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 15.5px;
  color: white;
  margin-left: 0;
  gap: 0;
`;

export const Username = styled.a`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  text-overflow: unset;
  color: #fff;
  letter-spacing: -0.5px;
`;

export const Name = styled.a`
  border: 0px solid black;
  box-sizing: border-box;
  color: inherit;
  display: inline;
  font: inherit;
  font-family: inherit;
  list-style: none;
  margin: 0px;
  padding: 0px;
  position: relative;
  text-align: inherit;
  text-decoration: none;
  white-space: inherit;
  overflow-wrap: break-word;
  background-color: rgba(0, 0, 0, 0);
  font-size: 17.5px;
`;

export const Flex = styled.div`
  margin: 0;
  display: grid;
  gap: 0;
`;

export const Date = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 15px;
  margin: 0;
  white-space: nowrap;
  letter-spacing: -0.6px;
  margin-left: 7px;
  margin-top: 0px;
`;

export const ContentContainer = styled.p`
  display: grid;
  gap: 14px;
  margin-left: 50px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Content = styled.p`
  font-weight: normal;
  font-size: 15px;
  line-height: 20.5px;
  margin: 0;
`;

export const InformationDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0;
  border-top: 0;
  justify-content: space-between;
  .ant-btn {
    padding-left: 0px;
  }
`;

export const Action = styled.div`
  flex-direction: row;
  padding: 0;
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0;
  .ant-btn {
    color: #777;
    display: flex;
    align-items: center;
    gap: 0;
  }
  .ant-btn-icon {
    margin: 0;
    margin-inline-end: 0;
    font-size: 15px;
  }
`;

export const Count = styled.div`
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  margin: 0;
  text-overflow: unset;
  color: rgb(113, 118, 123);
  line-height: 0px;
`;

export const Linked = styled.a`
 font-size: 14px;
  font-weight: normal;
  margin: 0;
  opacity: 0.92;
  text-overflow: unset;
  color: rgb(113, 118, 123);
  -webkit-text-decoration: underline;
  text-decoration: underline;
  line-height: 20px;
  color: #6E6E6E;
`;

export const ActionCard = styled.div`
  padding: 14px 0;
  border-top: 1px solid #1e1e1e;
  border-bottom: 1px solid #1e1e1e;
`;
