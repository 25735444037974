import React, { useEffect } from 'react';
import { Form, Input, Button, notification, Typography } from 'antd';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import CustomLayout from "../../Layout";
import Cookies from 'js-cookie';
import { useAuth } from '../AuthContext';

const { Title, Paragraph } = Typography;

const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      message
      token
      account {
        userUID
      }
    }
  }
`;

const LoginForm = () => {
  const selectedLanguage = localStorage.getItem("preferredLanguage") || "fr";

  // Définition des textes selon la langue sélectionnée
  const welcomeText = selectedLanguage === "fr" ? "Bienvenue" : "Welcome";
  const loginButtonText = selectedLanguage === "fr" ? "Se connecter" : "Log in";
  const loginContinueDescription = selectedLanguage === "fr" ? "Connectez-vous pour publier du contenu et interagir sur Sendkr." : "Log in to post content and interact on Sendkr.";
  const loginEmailLabel = selectedLanguage === "fr" ? "Email" : "Email";
  const loginPasswordLabel = selectedLanguage === "fr" ? "Mot de passe" : "Password";
  const loginEmailPlaceholder = selectedLanguage === "fr" ? "Entrez votre email" : "Please enter your email";
  const loginPasswordPlaceholder = selectedLanguage === "fr" ? "Mot de passe" : "Password";
  const loginSuccessMessage = selectedLanguage === "fr" ? "Connexion réussie" : "Login successful";
  const loginSuccessDescription = selectedLanguage === "fr" ? "Vous êtes connecté avec succès." : "You have successfully logged in.";
  const loginErrorMessage = selectedLanguage === "fr" ? "Erreur de connexion" : "Login error";
  const loginErrorDescription = selectedLanguage === "fr" ? "Email ou mot de passe incorrect." : "Incorrect email or password.";

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { setIsAuthenticated, setUserUID } = useAuth();

  const [loginUser, { loading, error }] = useMutation(LOGIN);

  useEffect(() => {
    const handleLoginClick = () => {
      window.dataLayer.push({
        event: 'loginButtonClick',
        buttonName: loginButtonText,
      });
    };

    const loginButton = document.getElementById('loginButton');
    if (loginButton) {
      loginButton.addEventListener('click', handleLoginClick);
    }

    return () => {
      if (loginButton) {
        loginButton.removeEventListener('click', handleLoginClick);
      }
    };
  }, [loginButtonText]);

  const onFinish = async (values) => {
    try {
      values.email = values.email.toLowerCase();
      const { data } = await loginUser({ variables: { input: values } });
      const { token, account } = data.login;

      Cookies.set('_scom_at_jwt1_', token, { secure: true, sameSite: 'Strict' });

      setUserUID(account.userUID);
      setIsAuthenticated(true);

      notification.success({
        message: loginSuccessMessage,
        description: loginSuccessDescription,
        duration: 5,
        placement: "topLeft" 
      });

      navigate('/');
    } catch (error) {
      notification.error({
        message: loginErrorMessage,
        description: loginErrorDescription,
      });
    }
  };

  return (
    <CustomLayout>
      <Title style={{ marginBottom: 5 }} level={2}>{welcomeText}</Title>
      <Paragraph style={{ fontSize: 16, color: "#777", marginBottom: 16 }}>{loginContinueDescription}</Paragraph>
      <Form form={form} name="login" onFinish={onFinish} layout="vertical">
        <Form.Item
          name="email"
          label={loginEmailLabel}
          rules={[{ required: true, type: 'email', message: 'Veuillez entrer un email valide!' }]}
        >
          <Input size="large" placeholder={loginEmailPlaceholder} />
        </Form.Item>
        <Form.Item
          name="password"
          label={loginPasswordLabel}
          rules={[{ required: true, message: 'Veuillez entrer le mot de passe!' }]}
        >
          <Input.Password size="large" placeholder={loginPasswordPlaceholder} />
        </Form.Item>
        <Form.Item>
          <Button id="loginButton" size="large" type="primary" htmlType="submit" loading={loading}>
            {loginButtonText}
          </Button>
        </Form.Item>
        {error && <p style={{ color: 'red' }}>Erreur : {error.message}</p>}
      </Form>
    </CustomLayout>
  );
};

export default LoginForm;
