export const footerStyles = {
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	padding: "0",
  };
  
  export const antDropdownLinkFontSize = "21px";
  export const antDropdownLinkColor = "white";
  export const copyrightTextColor = "rgb(119, 119, 119)";
  export const copyrightTextFontSize = "12px";
  
  export const menuStyles = {
	fontSize: antDropdownLinkFontSize,
	color: antDropdownLinkColor,
  };
  
  export const linkStyles = {
	color: "inherit", // Utilise la couleur par défaut du texte
	fontWeight: "normal",
	textDecoration: "none", // Ajoute une ligne soulignée au texte
	marginRight: "20px", // Ajoute une marge à droite pour l'espacement entre les liens
  };
  