import styled from '@emotion/styled';
import { Button } from 'antd';

export const FeedGalleryContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
`;

export const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 16px;
  position: absolute;
  width: 100%;
  padding: 0 18px;
  z-index: 1;
`;

export const StyledButton = styled(Button)`
  color: #fff;
  background: rgba(16, 16, 16, 0.14);
  border-radius: 5rem;
  padding: 8px;
  border: 0;
`;

export const ImageContainer = styled.div`
  display: flex;
  gap: 7px;
  transition: transform 0.3s ease, filter 0.3s ease;
  transform: ${({ scrollPosition }) => `translateX(calc(-${scrollPosition * 50 + scrollPosition * 0.7}%))`};
`;

export const StyledImage = styled.img`
  object-fit: cover;
  width: ${({ showButtons }) => (showButtons ? 'calc(49.3%)' : '100%')};
  height: auto;
  flex: 0 0 auto;
  transition: filter 0.3s ease;
  margin-left: ${({ index }) => (index !== 0 ? '0' : '0')};
  border-radius: 10px;
`;