// qm.js

import { gql } from '@apollo/client';

export const GET_ALL_PUBLICATIONS = gql`
  query GetAllPublications {
    getAllPublications {
      id
      originalPublicationId
      content
      gallery {
        url
      }
      video
      url
      slug
      tag
      originalPublicationDate
      createdAt
      repostCount
      account {
        fullName
        username
        profilePicture
        userUID
      }
      originalAuthor {
        id
        fullName
        username
        profilePicture
      }
      discount {
        code
        percentage
        color
      }
    }
  }
`;

export const GET_LIKED_PUBLICATIONS = gql`
  query GetLikedPublications($userId: ID!) {
    getLikedPublications(userId: $userId) {
      originalPublicationId
    }
  }
`;

