import { gql } from '@apollo/client';

export const GET_PUBLICATION_BY_SLUG = gql`
  query GetPublicationBySlug($slug: String!) {
    getPublicationBySlug(slug: $slug) {
      id
      userId
      content
      gallery {
        url
      }
      video
      url
      slug
      tag
      createdAt
      isRepost
      repostCount
      originalPublicationId
      originalPublicationDate
      originalAuthor {
        id
        fullName
        username
        profilePicture
      }
      account {
        fullName
        username
        profilePicture
        userUID
      }
      discount {
        code
        percentage
        color
      }
    }
  }
`;

export const GET_LIKED_PUBLICATIONS = gql`
  query GetLikedPublications($userId: ID!) {
    getLikedPublications(userId: $userId) {
      originalPublicationId
    }
  }
`;
