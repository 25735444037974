import { gql } from '@apollo/client';

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($userUID: String!) {
    deleteAccount(userUID: $userUID) {
      success
      message
    }
  }
`;
