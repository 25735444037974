import React, { useState, useMemo } from 'react';
import { Space } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useInView } from 'react-intersection-observer';
import { FeedGalleryContainer, ButtonContainer, StyledButton, ImageContainer, StyledImage } from './Styles';

const FeedGallery = ({ images }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [prevButtonVisible, setPrevButtonVisible] = useState(false);
  const showButtons = images.length > 1;

  useMemo(() => {
    setPrevButtonVisible(scrollPosition !== 0);
  }, [scrollPosition]);

  const handlePrev = () => {
    const newPosition = Math.max(scrollPosition - 1, 0);
    setScrollPosition(newPosition);
  };

  const handleNext = () => {
    const newPosition = Math.min(scrollPosition + 1, images.length - 2);
    setScrollPosition(newPosition);
  };

  return (
    <FeedGalleryContainer>
      {showButtons && (
        <ButtonContainer>
          <Space>
            {prevButtonVisible && (
              <StyledButton
                type="text"
                icon={<LeftOutlined />}
                onClick={handlePrev}
              />
            )}
            <StyledButton
              type="text"
              icon={<RightOutlined />}
              onClick={handleNext}
              disabled={scrollPosition === images.length - 2}
              style={{
                display: showButtons && images.length > 2 ? 'inline-grid' : 'none',
              }}
            />
          </Space>
        </ButtonContainer>
      )}
      <ImageContainer scrollPosition={scrollPosition}>
        {images.map((image, index) => (
          <LazyImage key={index} src={image.src} alt={`${index}`} showButtons={showButtons} />
        ))}
      </ImageContainer>
    </FeedGalleryContainer>
  );
};

const LazyImage = ({ src, alt, showButtons }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '100px',
  });

  return (
    <StyledImage
      showButtons={showButtons} // Ensure showButtons is passed to StyledImage
      ref={ref}
      src={inView ? src : ''}
      alt={alt}
      loading="lazy"
    />
  );
};


export default FeedGallery;
