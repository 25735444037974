import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../components/User/AuthContext';
import { useQuery } from '@apollo/client';
import { GET_PUBLICATION_BY_SLUG, GET_LIKED_PUBLICATIONS } from './qm'; // Adjust path as necessary
import usePublicationActions from '../hookp/usePublicationActions';
import moment from 'moment';

import CustomLayout from '../../components/Layout';
import FeedCard from '../Feed/FeedCard';
import CommentSection from "./ComentSection";

const Product = () => {
  const { slug } = useParams();
  const { isAuthenticated, userUID } = useAuth();
  const { handleRepost, handleLike, handleDislike, handleDelete, handleReport } = usePublicationActions();

  // Query to get publication details
  const { loading, error, data } = useQuery(GET_PUBLICATION_BY_SLUG, {
    variables: { slug },
    fetchPolicy: 'cache-first',
  });

  // Query to get liked publications by the user
  const { loading: likedLoading, error: likedError, data: likedData } = useQuery(GET_LIKED_PUBLICATIONS, {
    variables: { userId: userUID },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (!loading && !error) {
      // Proceed with your logic that depends on the loaded data
    }
  }, [loading, error]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const { getPublicationBySlug: publication } = data;

  const formatDate = (date) => {
    const now = moment();
    const publicationDate = moment(date);
    const diffInMinutes = now.diff(publicationDate, 'minutes');
    const diffInHours = now.diff(publicationDate, 'hours');
    const diffInDays = now.diff(publicationDate, 'days');

    if (diffInMinutes < 1) {
      return '1 min';
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} mins`;
    } else if (diffInHours < 24) {
      return `${diffInHours} h`;
    } else {
      return `${diffInDays} d`;
    }
  };

  return (
    <CustomLayout>
      <FeedCard
     id={publication.id}
     avatar={publication.account ? publication.account.profilePicture || '/default-avatar.png' : '/default-avatar.png'}
     name={publication.account ? publication.account.fullName || 'Default Username' : 'Default Username'}
     username={publication.account ? publication.account.username || 'Default Username' : 'Default Username'}
     date={formatDate(publication.createdAt)}
     content={publication.content}
     account={publication.account ? publication.account.userUID  || 'userUID' : 'userUID'}
     photos={publication.gallery}
     originalPublicationDate={formatDate(publication.originalPublicationDate)}
     video={publication.video}
     url={publication.url}
     tag={publication.tag}


     onRepost={handleRepost}
     discount={publication.discount} // Informations de réduction passées en tant que prop
     slug={publication.slug}
     onLike={handleLike}
     onDislike={handleDislike}
     onDelete={handleDelete}
     onReport={handleReport}
        likedPublications={likedData ? likedData.getLikedPublications.map(publication => publication.originalPublicationId) : []}
        initialRepostCount={publication.repostCount}
        originalAuthor={publication.originalAuthor}
      />

      {isAuthenticated && 
        <CommentSection publicationId={publication.originalPublicationId} isAuthenticated={isAuthenticated} />
      }
    </CustomLayout> 
  );
};

export default Product;
