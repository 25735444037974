import React, { useState } from "react";
import { Button } from "antd";
import { GlobalOutlined } from "@ant-design/icons"; // Import de l'icône de langue

const LanguageSelector = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("preferredLanguage") || "fr",
  );

  const changeLanguage = () => {
    const newLanguage = selectedLanguage === "fr" ? "en" : "fr";
    localStorage.setItem("preferredLanguage", newLanguage);
    setSelectedLanguage(newLanguage);
    setTimeout(() => {
      // Vérifier si la langue stockée dans le localStorage a réellement changé
      if (localStorage.getItem("preferredLanguage") === newLanguage) {
        window.location.reload(); // Rafraîchir la page pour appliquer la nouvelle langue
      }
    }, 1000);
  };

  return (
    <div style={{ display: "inline-block" }}>
      <Button
        bordered={true}
        type="default"
        onClick={changeLanguage}
        icon={<GlobalOutlined />} // Utilisation de l'icône de langue
      >
        {selectedLanguage === "fr" ? "Switching to English" : "Français"}
      </Button>
    </div>
  );
};

export default LanguageSelector;
