import React, { useEffect, useState } from "react";
import { useAuth } from "../../components/User/AuthContext";
import { useNavigate } from "react-router-dom";
import CustomLayout from "../../components/Layout";
import PublicationForm from "../../components/User/PublicationForm";

function Publication() {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        if (typeof isAuthenticated === 'boolean' && !isAuthenticated) {
          navigate("/u/login");
        }
      } catch (error) {
        // Gérer l'erreur, par exemple afficher un message d'erreur à l'utilisateur.
        console.error("Erreur lors de la vérification de l'authentification:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuthentication();
  }, [isAuthenticated, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <CustomLayout>
      <PublicationForm />
    </CustomLayout>
  );
}

export default Publication;
