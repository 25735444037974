// src/store/index.js

import { createStore, combineReducers } from 'redux';
import publicationsReducer from './reducers/publicationsReducer'; // Import your publications reducer

const rootReducer = combineReducers({
  publications: publicationsReducer,
  // Add other reducers here if needed
});

const store = createStore(rootReducer);

export default store;
