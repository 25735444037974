import React, { useState, useRef } from 'react';
import { Form, Input, Button, message, Typography } from 'antd';
import Gallery from './Gallery';
import { useMutation } from '@apollo/client';
import { CREATE_PUBLICATION } from './mutations';
import axios from 'axios';
import VideoUploader from './VideoUploader';
import { useAuth } from '../AuthContext';
import { FileImageOutlined, VideoCameraAddOutlined } from '@ant-design/icons';
import Discount from './Discount';

const { Title } = Typography;

const PublicationForm = () => {
  const { isAuthenticated, userUID } = useAuth();
  const [content, setContent] = useState('');
  const [images, setImages] = useState([]);
  const [videoFile, setVideoFile] = useState(null);
  const [url, setUrl] = useState('');
  const [discount, setDiscount] = useState({ code: '', percentage: 0, color: '#1677ff' });
  const [createPublication, { loading }] = useMutation(CREATE_PUBLICATION);
  const [selectedMediaType, setSelectedMediaType] = useState(null); // 'image' or 'video'
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("preferredLanguage") || "fr");
  const [isSubmitting, setIsSubmitting] = useState(false); // State to manage submission status
  const [tag, setTag] = useState(''); // Ajout du champ tag

  const formRef = useRef(null); // Create a ref for the form instance

  const handleContentChange = (e) => setContent(e.target.value);
  const handleUrlChange = (e) => setUrl(e.target.value);
  const handleTagChange = (e) => setTag(e.target.value); // Gestion du changement de tag
  const handleImageSelection = (selectedFiles) => {
    setImages(selectedFiles);
    setSelectedMediaType('image');
  };
  const handleVideoUpload = (file) => {
    setVideoFile(file);
    setSelectedMediaType('video');
  };
  const handleDiscountChange = (newDiscount) => setDiscount(newDiscount);

  const uploadFileCloudinary = async (file, type) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'cloudpscomapp');
    formData.append('folder', 'piscomapp');

    const url = `https://api.cloudinary.com/v1_1/dqdjbibmo/${type}/upload`;

    try {
      const response = await axios.post(url, formData, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      });
      return response.data.secure_url;
    } catch (error) {
      console.error(`Error uploading ${type}:`, error);
      throw new Error(`Failed to upload ${type}`);
    }
  };

  const handleSubmit = async (values) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
  
    try {
      // Validation: Ensure either images or video is present
      if (!images.length && !videoFile) {
        message.error(selectedLanguage === "fr" ? "Ajoutez au moins une image ou une vidéo." : "Add at least one image or video.");
        return;
      }
  
      let mediaUrl = null;
      if (selectedMediaType === 'image') {
        const uploadedImages = await Promise.all(images.map(file => uploadFileCloudinary(file, 'image')));
        mediaUrl = uploadedImages.map(url => ({ url }));
      } else if (selectedMediaType === 'video') {
        if (videoFile) {
          mediaUrl = await uploadFileCloudinary(videoFile, 'video');
        }
      }
  
      await createPublication({
        variables: {
          input: {
            userId: userUID,
            content: values.content,
            gallery: selectedMediaType === 'image' ? mediaUrl : [],
            video: selectedMediaType === 'video' ? mediaUrl : null,
            url: values.url,
            discount,
            tag, // Ajout du tag à la mutation
          }
        }
      });
  
      message.success(selectedLanguage === "fr" ? 'Produit publié avec succès' : 'Publication created successfully');
  
      // Reset form fields
      formRef.current.resetFields();
  
      // Reset local component state
      setContent('');
      setImages([]);
      setVideoFile(null);
      setUrl('');
      setDiscount({ code: '', percentage: 0, color: '#1677ff' });
      setSelectedMediaType(null);
      setTag(''); // Réinitialiser le tag
    } catch (error) {
      console.error('Error creating publication:', error);
      message.error('Failed to create publication. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Form ref={formRef} onFinish={handleSubmit}>
        <Form.Item label={selectedLanguage === "fr" ? "Contenu" : "Content"} name="content" required>
          <Input.TextArea value={content} onChange={handleContentChange}  />
        </Form.Item>
        <Form.Item label="URL" name="url" required>
          <Input value={url} onChange={handleUrlChange}  />
        </Form.Item>
        <Form.Item label="Tag" name="tag" required> {/* Ajout du champ tag */}
          <Input value={tag} onChange={handleTagChange} />
        </Form.Item>
        <div style={{ display: 'flex', gap: 16, marginBottom: 24 }}>
          <Button type="default" onClick={() => setSelectedMediaType('image')} disabled={selectedMediaType === 'video'}>
            <FileImageOutlined /> {selectedLanguage === "fr" ? "Images" : "Images"}
          </Button>
          <Button type="default" onClick={() => setSelectedMediaType('video')} disabled={selectedMediaType === 'image'}>
            <VideoCameraAddOutlined /> {selectedLanguage === "fr" ? "Vidéo" : "Video"}
          </Button>
        </div>
        {selectedMediaType === 'image' && (
          <Form.Item>
            <Gallery onUpload={handleImageSelection} />
          </Form.Item>
        )}
        {selectedMediaType === 'video' && (
          <Form.Item>
            <VideoUploader onUpload={handleVideoUpload} />
          </Form.Item>
        )}
        <Form.Item>
          <Discount onDiscountChange={handleDiscountChange} />
        </Form.Item>
        <Form.Item style={{textAlign: "right"}}>
          <Button type="primary" htmlType="submit" loading={loading || isSubmitting}>
            {selectedLanguage === "fr" ? 'Publié mon produit' : 'Create Publication'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PublicationForm;
