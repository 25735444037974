import React from "react";
import { Button } from "antd";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const MenuItem = styled(Link)`
  color: ${({ isActive }) => (isActive ? "#000" : "#FFF")};
`;

const IconWrapper = styled.span`
  font-size: 32px;
  height: 42px;
`;

const Level = ({ to, onClick, isActive, icon: Icon, label }) => (
  <MenuItem to={to} onClick={onClick} isActive={isActive(to)}>
    <Button
      type="link"
      style={{
        color: isActive(to) ? "#FFF" : "#999",
        gap: 7,
        fontSize: 18,
        fontWeight: "normal",
        padding: 0,
      }}
    >
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <span>{label}</span>
    </Button>
  </MenuItem>
);

export default Level;
