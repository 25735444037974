import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

const ResponsiveVideo = ({ video }) => {
  const videoRef = useRef(null);
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  useEffect(() => {
    let timer;
    if (inView) {
      timer = setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.play();
        }
      }, 1000);
    } else {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
    return () => clearTimeout(timer);
  }, [inView]);

  return (
    <div ref={ref} style={{ minHeight: '100px', marginBottom: '20px' }}>
      {video && (
        <video
          ref={videoRef}
          style={{
            width: 'auto',
            maxWidth: '100%',
            maxHeight: '500px',
            borderRadius: '12px',
            border: '1px solid #1e1e1e',
          }}
          controls={false}
          muted
          loop
          playsInline
          autoPlay
        >
          <source src={video} type="video/mp4" />
          Votre navigateur ne supporte pas la balise vidéo.
        </video>
      )}
    </div>
  );
};

export default ResponsiveVideo;
