import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Name,
  HeaderInformations,
  InformationsContainer,
  Informations,
  Content,
  Username,
  Flex,
  Date,
} from "../Styles/StylesCard";
import { useNavigate, Link } from "react-router-dom";
import { useInView } from 'react-intersection-observer';

const HeaderCard = ({ name, username, tag, date, content }) => {
  const navigate = useNavigate();
  const [isContentExpanded, setIsContentExpanded] = useState(false);
  const { ref, inView, entry } = useInView({
    threshold: 0.5
  });

  const handleUsernameClick = () => {
    navigate(`/${username}`);
  };

  const toggleContent = () => {
    setIsContentExpanded(!isContentExpanded);
  };

  const renderContent = () => {
    if (typeof content === "string") {
      if (!isContentExpanded && content.length > 100) {
        return content.substring(0, 100) + "...";
      }
      return isContentExpanded ? content : content.substring(0, 100);
    } else if (React.isValidElement(content)) {
      return content;
    }
    return <div>Contenu non pris en charge</div>;
  };

  return (
    <HeaderInformations ref={ref}>
      <Flex>
        <InformationsContainer>
          <Informations>
            <Name onClick={handleUsernameClick}>{name}</Name>
            <Username onClick={handleUsernameClick}>{username}</Username>
            <Date>{date}</Date>
          </Informations>
          <Content>
            {renderContent()}
            {typeof content === "string" && content.length > 100 && (
              <Link className="voir-plus" onClick={toggleContent}>
                {isContentExpanded ? "Voir moins" : "Voir plus"}
              </Link>
            )}
            {/* Link for the tag */}
            {tag && (
  <div>
    <Link to={`/tag/${tag}`} className="tag-link">
      #{tag}
    </Link>
  </div>
)}

          </Content>
        </InformationsContainer>
      </Flex>
    </HeaderInformations>
  );
};

HeaderCard.propTypes = {
  name: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
};

export default HeaderCard;
